import {createContext, useContext, useReducer} from 'react';

export const DashboardContext = createContext({});

export const useDashboardContext = () => useContext(DashboardContext);

const setterReducer = (state, action) => action;

export const DashboardContextProvider = ({children}) => {
    const [hedgingEnabled, setHedgingEnabled] = useReducer(setterReducer, undefined);
    const [hedgeProvider, setHedgeProvider] = useReducer(setterReducer, null);
    const [hedgeCollateral, setHedgeCollateral] = useReducer(setterReducer, {
        total: '-',
        free: NaN,
        used: NaN,
        openPnL: NaN,
    });
    const [hedgePositions, setHedgePositions] = useReducer(setterReducer, []);
    const [glpWallet, setGlpWallet] = useReducer(setterReducer, null);
    const [glpBalance, setGLPBalance] = useReducer(setterReducer, {
        GLP: '-',
        USD: '-',
    });

    return (
        <DashboardContext.Provider value={{
            hedgingEnabled,
            setHedgingEnabled,
            hedgeProvider,
            setHedgeProvider,
            hedgeCollateral,
            setHedgeCollateral,
            hedgePositions,
            setHedgePositions,
            glpWallet,
            setGlpWallet,
            glpBalance,
            setGLPBalance,
        }}>
            {children}
        </DashboardContext.Provider>
    );
};
