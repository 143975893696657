import axios from 'axios';
import {useAuth0} from '@auth0/auth0-react';
import {useState, createContext, useContext} from 'react';

const APIContext = createContext({});

export const useAPI = () => useContext(APIContext);

export const APIProvider = ({children}) => {
    const {getAccessTokenSilently} = useAuth0();

    const axiosInstance = axios.create({
        baseURL: new URL('api/', process.env.REACT_APP_AUTH0_AUDIENCE).toString(),
        timeout: 30000,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const [csrfToken, setCsrfToken] = useState('');

    axiosInstance.interceptors.request.use(async function setHeaders (req) {
        const accessToken = await getAccessTokenSilently();
        req.headers['Authorization'] = `Bearer ${accessToken}`;
        req.headers['X-CSRF-TOKEN'] = csrfToken;
        return req;
    });

    axiosInstance.interceptors.response.use(function getHeaders (res) {
        setCsrfToken(res.headers['x-csrf-token']);
        return res;
    });

    return (
        <APIContext.Provider value={{api: axiosInstance}}>
            {children}
        </APIContext.Provider>
    );
};
