import {Link} from 'react-router-dom';

import {toast} from 'react-toastify';
import NavDropdown from 'react-bootstrap/NavDropdown';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircleUser} from '@fortawesome/free-solid-svg-icons';

import {useAPI} from '../../APIProvider';
import {useAuth0} from '@auth0/auth0-react';

function UserDropdown () {
    const {api} = useAPI();
    const {logout} = useAuth0();

    function resetPassword () {
        api.post('account/resetPassword').then(() => {
            toast.info(`The password reset email should reach your inbox shortly.`, {
                position: 'top-center',
            });
        }).catch((err) => {
            console.error(err);
            toast.error(`An error occurred generating the password reset email.`, {
                position: 'top-center',
            });
        });
    }

    const doLogout = () => {
        logout({
            logoutParams: {
                returnTo: window.location.origin,
            },
        });
    };

    return (
        <NavDropdown id="headerUserDropdown"
                     align="end"
                     menuVariant="dark"
                     renderMenuOnMount={true}
                     title={<FontAwesomeIcon icon={faCircleUser} size="2x" />}
                     className="p-1 nav-light"
        >
            <NavDropdown.Item as={Link} to="/profile">Profile</NavDropdown.Item>
            <NavDropdown.Item onClick={resetPassword}>Change Password</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item onClick={doLogout}>Sign Out</NavDropdown.Item>
        </NavDropdown>
    );
}

export default UserDropdown;
