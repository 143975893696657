import {useState, useEffect} from 'react';
import ReactApexChart from 'react-apexcharts';

import {numericFormatter} from 'react-number-format';
import colourGradient from '../../../../src/colours/gradient';

/**
 * Height of the chart as a multiple of the number of positions.
 * @type {number}
 */
const HEIGHT_FACTOR = 30;

const AMOUNT_PRECISION = 4;

const HedgePositionsChart = ({positions, ...props}) => {
    const [series, setSeries] = useState([]);
    const [options] = useState({
        chart: {
            type: 'bar',
            toolbar: {show: false},
            background: 'transparent',
            offsetX: 0,
            offsetY: 0,
            parentHeightOffset: 0,
        },
        plotOptions: {
            bar: {
                distributed: true,
                horizontal: true,
                dataLabels: {
                    position: 'top',
                },
            },
        },
        colors: colourGradient('#16c2b4', '#1b5bad', positions.length),
        theme: {
            mode: 'dark',
        },
        dataLabels: {
            enabled: true,
            style: {
                colors: ['#fff'],
            },
            formatter: (val, opt) => {
                const [base] = opt.w.globals.labels[opt.dataPointIndex].split('/');
                const data = opt.w.config.series[opt.seriesIndex].data;
                const notional = numericFormatter(Math.abs(data[opt.dataPointIndex].n)?.toFixed?.(AMOUNT_PRECISION), {displayType: 'text', thousandSeparator: ',', decimalScale: 2, prefix: '$'});
                const side = data[opt.dataPointIndex].s;

                if (!data[opt.dataPointIndex].n) {
                    return `${base} ${notional}`;
                }

                return `${base} ${notional} ${side}`;
            },
            dropShadow: {
                enabled: true,
            },
        },
        stroke: {
            show: true,
            width: 0.5,
            colors: ['#fff'],
        },
        grid: {
            show: false,
            padding: {
                top: -20,
            },
        },
        xaxis: {
            min: 0,
            max: 1.5,
            labels: {show: false},
            // Note: Appears to be a bug in apexcharts - turning off the x-axis border turns them both off.  Toggling the y-axis border has no effect.
            axisBorder: {show: false},
            axisTicks: {show: false},
        },
        yaxis: {
            labels: {show: false},
            axisBorder: {show: false},
            axisTicks: {show: false},
        },
        legend: {
            show: false,
        },
        tooltip: {
            enabled: true,
            theme: 'dark',
            followCursor: true,
            y: {
                title: {
                    formatter: () => '',
                },
                formatter: (val, opt) => {
                    const data = opt.w.config.series[opt.seriesIndex].data;
                    const notional = numericFormatter(Math.abs(data[opt.dataPointIndex].n)?.toFixed?.(AMOUNT_PRECISION), {displayType: 'text', thousandSeparator: ',', decimalScale: 2, prefix: '$'});
                    const side = data[opt.dataPointIndex].s;

                    return `${notional} ${side}`;
                },
            },
        },
    });

    useEffect(() => {
        let maxPosition = positions.reduce((max, position) => Math.max(max, position.notionalSize), 0) || 1;
        setSeries([{
            name: 'Exposure',
            data: positions.map(c => ({
                x: c.symbol,
                y: c.notionalSize / maxPosition,
                n: c.notionalSize,
                s: c.side,
            })),
        }]);
    }, [positions]);

    return (
        <div>
            <ReactApexChart options={options} series={series} type="bar" height={positions.length * HEIGHT_FACTOR} {...props} />
        </div>
    );
};

export default HedgePositionsChart;
