import {useAuth0} from '@auth0/auth0-react';
import Button from 'react-bootstrap/Button';

const SignupButton = (props) => {
    const {children, ...otherProps} = props;
    const {loginWithRedirect} = useAuth0();

    const handleSignup = async () => {
        await loginWithRedirect({
            appState: {
                returnTo: '/signup',
            },
            authorizationParams: {
                // eslint-disable-next-line camelcase
                screen_hint: 'signup',
            },
        });
    };

    return (<Button {...otherProps} onClick={handleSignup}>
        {children}
    </Button>);
};

export default SignupButton;
