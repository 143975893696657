import {useState} from 'react';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

/*
 * Wrapper to make using Bootstrap tooltips less ugly.
 * https://react-bootstrap.netlify.app/components/overlays/#tooltips
 *
 * Use it like this, for example:
 * <HoverTip tooltip="This is a tooltip"><span>This is some text</span></HoverTip>
 *
 * To have html in the tooltip, pass a snippet or component instead of a string, for example:
 * <HoverTip tooltip={<>This is a <strong>tooltip</strong></>}><span>This is some text</span></HoverTip>
 * <HoverTip tooltip={<Foo />}><span>This is some text</span></HoverTip>
 */

function HoverTip ({tooltip, className, expireAfter = 5000, delay, children}) {
    const [show, setShow] = useState(false);
    let hideTimeout;

    function toggled (nextShow) {
        if (expireAfter) {
            if (nextShow) {
                hideTimeout = setTimeout(() => {
                    setShow(false);
                }, expireAfter);
            } else {
                clearTimeout(hideTimeout);
            }
        }

        setShow(nextShow);
    }

    return (
        <>
            {tooltip &&
                <OverlayTrigger
                    onToggle={toggled}
                    show={show}
                    delay={delay}
                    overlay={
                        <Tooltip className={className}>{tooltip}</Tooltip>
                    }
                >
                    {children}
                </OverlayTrigger>
            }
            {!tooltip &&
                {children}
            }
        </>
    );
}

export default HoverTip;
