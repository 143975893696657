import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

function HowToModal ({showState, setShowState}) {
    function hide () {
        setShowState(false);
    }

    return (
        <Modal
            show={showState}
            onHide={hide}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onClick={(e) => {
                e.stopPropagation();
            }}>
            <Modal.Header closeButton>
                <Modal.Title>How to use Arcus</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="youtube-container">
                    <iframe className="responsive-iframe"
                            src={`${process.env.REACT_APP_YOUTUBE_HOW_TO_ARCUS_URL}`}
                            title="How to use Arcus"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={hide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default HowToModal;
