import {useState, useEffect} from 'react';

import ReactApexChart from 'react-apexcharts';
import {numericFormatter} from 'react-number-format';

import colourGradient from '../../../../src/colours/gradient';

/**
 * Height of the chart as a multiple of the number of tokens in the composition.
 * @type {number}
 */
const HEIGHT_FACTOR = 30;

const AMOUNT_PRECISION = 4;
const PERCENT_PRECISION = 2;

const GLPCompositionChart = ({composition, ...props}) => {
    const [series, setSeries] = useState([]);
    const [options] = useState({
        chart: {
            type: 'bar',
            toolbar: {show: false},
            background: 'transparent',
            offsetX: 0,
            offsetY: 0,
            parentHeightOffset: 0,
        },
        plotOptions: {
            bar: {
                distributed: true,
                horizontal: true,
                dataLabels: {
                    position: 'top',
                },
            },
        },
        colors: colourGradient('#16c2b4', '#1b5bad', composition.length),
        theme: {
            mode: 'dark',
        },
        dataLabels: {
            enabled: true,
            style: {
                colors: ['#fff'],
            },
            formatter: (val, opt) => {
                const symbol = opt.w.globals.labels[opt.dataPointIndex];
                const exposure = numericFormatter(opt.w.config.series[opt.seriesIndex].data[opt.dataPointIndex].e?.toFixed?.(AMOUNT_PRECISION),
                    {displayType: 'text', thousandSeparator: ',', decimalScale: 2, prefix: '$'});
                const percentage = `${+(val * 100).toFixed(PERCENT_PRECISION)}%`;
                return `${symbol} ${percentage} (${exposure})`;
            },
            dropShadow: {
                enabled: true,
            },
        },
        stroke: {
            show: true,
            width: 0.5,
            colors: ['#fff'],
        },
        grid: {
            show: false,
            padding: {
                top: -20,
            },
        },
        xaxis: {
            max: 0.5,
            labels: {show: false},
            // Note: Appears to be a bug in apexcharts - turning off the x-axis border turns them both off.  Toggling the y-axis border has no effect.
            axisBorder: {show: false},
            axisTicks: {show: false},
        },
        yaxis: {
            labels: {show: false},
            axisBorder: {show: false},
            axisTicks: {show: false},
        },
        legend: {
            show: false,
        },
        tooltip: {
            enabled: false,
        },
    });

    useEffect(() => {
        setSeries([{
            name: 'Exposure',
            data: composition.map(c => ({
                x: c.symbol,
                y: c.proportion,
                e: c.valueUSD,
            })),
        }]);
    }, [composition]);

    return (
        <div id="chart">
            <ReactApexChart options={options} series={series} type="bar" height={composition.length * HEIGHT_FACTOR} {...props} />
        </div>
    );
};

export default GLPCompositionChart;
