import {useEffect} from 'react';
import {useAuth0} from '@auth0/auth0-react';

import PageLoader from '../PageLoader';
import {useNavigate} from 'react-router-dom';

const defaultOnRedirecting = () => (
    <PageLoader />
);

/*
 * Mimicking the functionality of Auth0's built-in withAuthenticationRequired()
 * to wrap a page with a redirect if the user has not completed sign-up.
 */

// eslint-disable-next-line arrow-body-style
export const withSignupRequired = (Component, options = {}) => {
    return function WithSignupRequired (props) {
        const {
            onRedirecting = defaultOnRedirecting,
        } = options;

        const navigate = useNavigate();
        const {user, isLoading} = useAuth0();

        useEffect(() => {
            if (isLoading || user?.['arcus_signup_complete']) {
                return;
            }
            if (user && !user['arcus_signup_complete']) {
                navigate('/signup');
            }
        }, [
            navigate,
            isLoading,
            user,
        ]);

        return user?.['arcus_signup_complete'] ? <Component {...props} /> : onRedirecting();
    };
};

export const SignupGuard = ({component}) => {
    const Component = withSignupRequired(component, {
        onRedirecting: () => (
            <PageLoader />
        ),
    });

    return <Component />;
};
