import Accordion from 'react-bootstrap/Accordion';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import GLPWallet from './GLPWallet';
import HedgeProvider from './HedgeProvider';
import FeeWallet from './FeeWallet';

import './index.css';

function Balances () {
    return (
        <Container className="p-3">
            <Row className="justify-content-md-center mb-4">
                <Col>
                    <Accordion alwaysOpen={true}>
                        <GLPWallet eventKey="glp" />
                        <HedgeProvider eventKey="hedge" />
                        <FeeWallet eventKey="fee" />
                    </Accordion>
                </Col>
            </Row>
        </Container>
    );
}

export default Balances;
