import Modal from 'react-bootstrap/Modal';
import {QRCodeSVG} from 'qrcode.react';
import Button from 'react-bootstrap/Button';

function QRCodeModal ({show, onHide, title = 'QR Code', value = '', ...props}) {
    return (
        <Modal
            show={show}
            onHide={onHide}
            size="w-auto"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            {...props}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="bg-light text-dark">
                <div className="d-flex justify-content-center mb-3">
                    <QRCodeSVG value={value} />
                </div>
                <div className="text-center font-monospace">{value}</div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default QRCodeModal;
