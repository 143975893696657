import {useState, useEffect} from 'react';
import {useSearchParams} from 'react-router-dom';

import {useAPI} from '../../components/APIProvider';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Aum from './Aum';
import ChartCarousel from './GraphCarousel/carousel.jsx';
import Balances from './Balances';
import StopStartBtn from './ToggleButton/StopStartBtn';

import {DashboardContextProvider} from './Context';

import './index.css';
import HowToModal from '../../components/HowToModal/index.jsx';

const Dashboard = () => {
    const {api} = useAPI();
    const [userName, setUserName] = useState(null);
    const [query] = useSearchParams();
    const [showHowToModal, setShowHowToModal] = useState(false);

    async function getUser () {
        const response = await api.get('account');
        setUserName(response?.data?.preferredName);
    }

    useEffect(() => {
        const showHowTo = query.get('showHowTo');
        if (showHowTo && process.env.REACT_APP_YOUTUBE_HOW_TO_ARCUS_URL) {
            setShowHowToModal(true);
        }
        getUser().catch(console.error);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="arcus-background" style={{background: `url('${process.env.REACT_APP_ASSET_BUCKET}/arcus_landing_bg.jpg') center center/cover no-repeat`}}>
                <Header />
                <hr />
                <main>
                    <h2 className="text-center pt-3">Welcome to Arcus, <span>{userName}</span></h2>
                    <DashboardContextProvider>
                        <ChartCarousel />
                        <Aum />
                        <Balances />
                        <StopStartBtn />
                    </DashboardContextProvider>
                </main>
                <hr />
                <Footer />
            </div>
            <HowToModal showState={showHowToModal} setShowState={setShowHowToModal} />
        </>
    );
};

export default Dashboard;
