import {useState, useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import TimezoneSelect, {allTimezones} from 'react-timezone-select';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import AjaxForm from '../../components/AjaxForm';
import Terms from '../../components/TermsConditions';
import {useAPI} from '../../components/APIProvider';
import {useAuth0} from '@auth0/auth0-react';

import './index.css';
import ClipboardButton from '../../components/ClipboardButton';
import InputGroup from 'react-bootstrap/InputGroup';

const Profile = ({isSignUp}) => {
    const {api} = useAPI();
    const termsContext = Terms.useTermsAndConditions();
    const {getAccessTokenSilently, logout} = useAuth0();
    const navigate = useNavigate();
    const loc = useLocation();
    const [referralCode, setReferralCode] = useState(null);
    const [referralLink, setReferralLink] = useState('');
    const [emailAlert, setEmailAlert] = useState(null);
    const [alertQueryInProgress, setAlertQueryInProgress] = useState(true);

    // TODO: Fetch existing user data and populate defaultValues in the form.

    const apiPath = isSignUp ? 'signup' : 'account';

    const [user, setUser] = useState({
        firstName: '',
        lastName: '',
        preferredName: '',
        arcusReferralCode: '',
        referralLink: '',
    });
    const [tz, setTz] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);

    function onSubmitted (err, data) {
        if (!isSignUp) {
            return;
        }

        if (!err) {
            getAccessTokenSilently({cacheMode: 'off'})
                .then(() => navigate('/dashboard'))
                .catch(() => logout());
        }
    }

    function preloadUser (signal) {
        api.get(apiPath, {signal}).then((response) => {
            setUser({
                firstName: response.data.firstName,
                lastName: response.data.lastName,
                preferredName: response.data.preferredName,
                arcusReferralCode: response.data.arcusReferralCode,
            });

            if (response.data.referralCode) {
                setReferralLink(`${window.location.origin}/referral?code=${response.data.referralCode}`);
            }

            const code = new URLSearchParams(loc.search).get('referralCode');

            if (response.data.arcusReferralCode) {
                setReferralCode(response.data.arcusReferralCode);
            } else if (code) {
                setReferralCode(code);
            }

            window.history.replaceState({}, document.title, window.location.pathname);

            if (response.data.timezone) {
                setTz(response.data.timezone);
            }
        }).catch(() => {
        });
    }

    function toggleEmailAlert () {
        setAlertQueryInProgress(true);
        api.post('account/alerts', {
            emailAlert: !emailAlert,
        }).then(response => {
            setEmailAlert(response.data.emailAlert);
            setAlertQueryInProgress(false);
        }).catch(error => {
            console.error(error);
            setAlertQueryInProgress(false);
        });
    }

    useEffect(() => {
        const controller = new AbortController();
        preloadUser(controller.signal);

        setAlertQueryInProgress(true);
        api.get('account/alerts', {
            signal: controller.signal,
        }).then(response => {
            setEmailAlert(response.data.emailAlert);
            setAlertQueryInProgress(false);
        }).catch(error => {
            console.error(error);
            setAlertQueryInProgress(false);
        });

        return () => {
            controller.abort();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="arcus-background" style={{background: `url('${process.env.REACT_APP_ASSET_BUCKET}/arcus_landing_bg.jpg') center center/cover no-repeat`}}>
            <Header />
            <hr />
            <main>
                {isSignUp &&
                    <>
                        <h2 className="text-center pt-3">Welcome to Arcus</h2>
                        <p className="text-center">Let&apos;s complete your sign-up:</p>

                        <Terms.Modal context={termsContext} />
                    </>
                }

                <div className="d-flex flex-column align-items-center justify-content-center pt-3">
                    <Card
                        bg="dark"
                        text="light"
                        className="mb-2 signup-card"
                    >
                        <Card.Header>Profile Details</Card.Header>
                        <Card.Body>
                            <AjaxForm formId="signupForm" method="POST" path={apiPath} onSubmitted={onSubmitted} valid={!isSignUp || termsContext.termsAccepted}>
                                <Row className="mb-3">
                                    <Form.Group as={Col} controlId="signupFirstName">
                                        <Form.Label>First Name(s)</Form.Label>
                                        <Form.Control type="text" name="firstName" defaultValue={user.firstName} />
                                        <Form.Text>
                                            <FontAwesomeIcon icon={faInfoCircle} />&nbsp;
                                            Leave real names blank if you prefer.
                                        </Form.Text>
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="signupLastName">
                                        <Form.Label>Last Name</Form.Label>
                                        <Form.Control type="text" name="lastName" defaultValue={user.lastName} />
                                    </Form.Group>
                                </Row>

                                <Form.Group className="mb-3" controlId="signupPrefName">
                                    <Form.Label>Preferred Name</Form.Label>
                                    <Form.Control type="text" name="preferredName" defaultValue={user.preferredName} required />
                                    <Form.Text>
                                        <FontAwesomeIcon icon={faInfoCircle} />&nbsp;
                                        How would you prefer to be addressed in communications?
                                    </Form.Text>
                                </Form.Group>

                                {(isSignUp || user.arcusReferralCode) &&
                                    <Form.Group className="mb-3" controlId="signupReferralCode">
                                        <Form.Label>Referral Code</Form.Label>
                                        <Form.Control type="text" name="referralCode" defaultValue={referralCode} disabled={!isSignUp || !!user.arcusReferralCode} data-lpignore="true" />
                                        {isSignUp &&
                                            <Form.Text>
                                                <FontAwesomeIcon icon={faInfoCircle} />&nbsp;
                                                Sign up with a referral code from an existing user for fee discounts.
                                            </Form.Text>
                                        }
                                    </Form.Group>
                                }

                                <Form.Group className="mb-3" controlId="signupTimezone">
                                    <Form.Label>Timezone Preference</Form.Label>
                                    <TimezoneSelect styles="border-color: #adb5bd;"
                                                    name="timezone"
                                                    value={tz}
                                                    onChange={setTz}
                                                    labelStyle="abbrev"
                                                    timezones={{
                                                        ...allTimezones,
                                                        'Africa/Johannesburg': 'Johannesburg',
                                                        'Africa/Harare': 'Harare',
                                                        'Indian/Mauritius': 'Mauritius',
                                                    }}
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        colors: {
                                                            primary: '#56a099',
                                                            primary75: '#adb5bd',
                                                            primary50: '#adb5bd',
                                                            primary25: '#56a099',

                                                            danger: '#212529',
                                                            dangerLight: '#212529',

                                                            neutral0: '#212529',
                                                            neutral5: '#212529',
                                                            neutral10: '#212529',
                                                            neutral20: '#f2f2f2',
                                                            neutral30: '#f2f2f2',
                                                            neutral40: '#f2f2f2',
                                                            neutral50: '#adb5bd',
                                                            neutral60: '#adb5bd',
                                                            neutral70: '#adb5bd',
                                                            neutral80: '#adb5bd',
                                                            neutral90: '#adb5bd',
                                                        },
                                                    })}
                                    />
                                    <Form.Text>
                                        <FontAwesomeIcon icon={faInfoCircle} />&nbsp;
                                        This timezone will be used for displaying any dates/times, unless otherwise specified.
                                    </Form.Text>
                                </Form.Group>

                                {isSignUp &&
                                    <Form.Group className="mb-3" controlId="signupAcceptTerms">
                                        <Terms.Checkbox context={termsContext} />
                                    </Form.Group>
                                }
                            </AjaxForm>
                        </Card.Body>
                    </Card>
                    {!isSignUp && referralLink &&
                        <Card
                            bg="dark"
                            text="light"
                            className="mb-2 referral-card"
                        >
                            <Card.Header>Referrals</Card.Header>
                            <Card.Body>
                                <Form.Group as={Col} controlId="signupFirstName">
                                    <Form.Label>Referral Link</Form.Label>
                                    <InputGroup>
                                        <Form.Control type="text" readOnly value={referralLink} data-lpignore="true" />
                                        <ClipboardButton variant="outline-secondary" text={referralLink} />
                                    </InputGroup>
                                </Form.Group>
                            </Card.Body>
                        </Card>
                    }
                    {!isSignUp &&
                        <Card
                                bg="dark"
                                text="light"
                                className="mb-2 alerting-card"
                        >
                            <Card.Header>Alerting</Card.Header>
                            <Card.Body>
                                <Form.Group as={Col} controlId="emailAlert">
                                    <Form.Switch
                                        id="email-alert-switch"
                                        name="emailAlert"
                                        label="Send Email Alerts"
                                        checked={!!emailAlert}
                                        value={true}
                                        onChange={() => toggleEmailAlert()}
                                        disabled={alertQueryInProgress}
                                    />
                                    <Form.Text id="emailAlertHelpBlock" muted>
                                        <FontAwesomeIcon icon={faInfoCircle} />&nbsp;
                                        Recommended to opt-in for email alerts so that we can let you know if a configuration or balance issue is preventing hedging from proceeding as normal.
                                    </Form.Text>
                                </Form.Group>
                            </Card.Body>
                        </Card>
                    }
                </div>
            </main>
            <hr />
            <Footer />
        </div>
    );
};

export default Profile;
