import {useState} from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Fade from 'react-bootstrap/Fade';

import {faCircleNotch, faTriangleExclamation, faCheck} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {useAPI} from '../APIProvider';

import './index.css';

const RESULT_ICON_FADE_MS = 3000;

function AjaxForm ({children, formId, method, path, submitText, onSubmitted, valid = true, hideSubmit, ...props}) {
    const {api} = useAPI();
    const [submitError, setSubmitError] = useState(null);
    const [busy, setBusy] = useState(false);
    const [complete, setComplete] = useState(false);

    async function onSubmit (e) {
        e.preventDefault();
        const url = e.target.dataset.path;
        const formData = new FormData(e.currentTarget);
        const data = {};
        for (let [key, value] of formData.entries()) {
            data[key] = value;
        }

        setComplete(false);
        setBusy(true);
        setSubmitError(null);
        let response;
        let requestError = null;
        try {
            response = await api({
                method: e.target.method || method,
                url,
                data,
            });
            setBusy(false);
            setComplete(true);
        } catch (error) {
            setBusy(false);
            setSubmitError(error.response?.data?.error || error.message);
            requestError = error;
        }
        if (typeof onSubmitted === 'function') {
            try {
                onSubmitted(requestError, response?.data);
            } catch (err) {
                console.error(err);
            }
        }
    }

    return (
        <Form id={formId} className="ajax-form" method={method} data-path={path} onSubmit={onSubmit} {...props}>
            <fieldset disabled={busy}>
                {children}
            </fieldset>
            <div className="ajax-form-footer">
                {!hideSubmit &&
                    <Button variant="primary" type="submit" disabled={busy || !valid}>
                        {submitText || 'Submit'}
                    </Button>
                }
                {busy && <FontAwesomeIcon icon={faCircleNotch} spin />}
                {!submitError &&
                    <Fade appear={false} in={complete} onEntered={() => setTimeout(() => setComplete(false), RESULT_ICON_FADE_MS)}>
                        <FontAwesomeIcon className="text-success" icon={faCheck} />
                    </Fade>
                }
                {submitError &&
                    <Alert variant="danger" dismissible onClose={() => setSubmitError(false)}>
                        <FontAwesomeIcon icon={faTriangleExclamation} /> {submitError}
                    </Alert>
                }
            </div>
        </Form>
    );
}

export default AjaxForm;
