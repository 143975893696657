import {useState, useEffect} from 'react';

import {useDashboardContext} from '../Context';
import {NumericFormat} from 'react-number-format';

const Aum = () => {
    const {glpBalance, hedgeCollateral} = useDashboardContext();
    const [aum, setAum] = useState(null);

    useEffect(() => {
        setAum((+glpBalance.USD || 0) + (+hedgeCollateral.total || 0));
    }, [glpBalance, hedgeCollateral]);

    return (
        <h4 className="text-center pt-1">
            Total Capital: <NumericFormat displayType="text" thousandSeparator={','} decimalScale={0} prefix={'$'} value={aum} />
        </h4>
    );
};

export default Aum;
