import './index.css';

import {useAuth0} from '@auth0/auth0-react';

import version from '../../src/version';

const Footer = () => {
    const {isAuthenticated} = useAuth0();

    return (
        <footer>
            <div className="footer-container">
                <div className="footer-column">
                    <h4>Ecosystem</h4>
                    <ul>
                        <li><a href="https://arbitrum.io/" target="_blank" rel="noreferrer">Arbitrum One</a></li>
                        <li><a href="https://gmx.io/" target="_blank" rel="noreferrer">GMX</a></li>
                        <li><a href="https://gmxio.gitbook.io/gmx/glp" target="_blank" rel="noreferrer">GLP</a></li>
                        <li><a href="https://www.binance.com/" target="_blank" rel="noreferrer">Binance</a></li>
                    </ul>
                </div>
                <div className="footer-column">
                    <h4>Legal & Compliance</h4>
                    <ul>
                        <li><a href={`${process.env.REACT_APP_ASSET_BUCKET}/terms.pdf`} target="_blank" rel="noreferrer">Terms & Conditions</a></li>
                        <li><a href={`${process.env.REACT_APP_ASSET_BUCKET}/privacy.pdf`} target="_blank" rel="noreferrer">Privacy Policy</a></li>
                    </ul>
                </div>
                <div className="footer-column">
                    <h4>Support</h4>
                    <ul>
                        <li><a href="https://support.arcusyield.com/hc/en-us/requests/new" target="_blank" rel="noreferrer">Support</a></li>
                        <li><a href="https://support.arcusyield.com/hc/en-us/sections/9109504355101-PRODUCT-FAQ" target="_blank" rel="noreferrer">FAQ</a></li>
                    </ul>
                </div>
            </div>
            <div className="version">
                {isAuthenticated &&
                    <p>
                        {version}
                    </p>
                }
            </div>
            <div className="powered-by">
                <p>Powered by <a href="https://arreltech.com/" target="_blank" rel="noreferrer"><img src={`${process.env.REACT_APP_ASSET_BUCKET}/arrel.png`} alt="Arrel" /></a></p>
            </div>
        </footer>
    );
};

export default Footer;
