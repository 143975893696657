import {useEffect, useState} from 'react';

import Accordion from 'react-bootstrap/Accordion';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';

import {NumericFormat} from 'react-number-format';

import {faTriangleExclamation, faInfoCircle, faQrcode, faCog, faMagnifyingGlassDollar, faHandHoldingDollar, faScaleBalanced} from '@fortawesome/free-solid-svg-icons';
import {faTether} from '../../../../src/fontawesome/custom-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {useAPI} from '../../../../components/APIProvider';
import AjaxForm from '../../../../components/AjaxForm';
import HoverTip from '../../../../components/HoverTip';
import ClipboardButton from '../../../../components/ClipboardButton';
import QRCodeModal from '../../../../components/QRCodeModal';
import BalanceAccordionTitle from '../BalanceAccordionTitle';
import CurrencyConversion from '../../../../components/CurrencyConversion';

import {chainIcons} from '../../../../src/fontawesome/icon-groups';

import './index.css';

const POLLING_INTERVAL_MS = 30000;

function FeeAccordionItem ({eventKey}) {
    const {api} = useAPI();
    const [feeStatus, setFeeStatus] = useState('loading');
    const [feeWallet, setFeeWallet] = useState(null);
    const [feeBalance, setFeeBalance] = useState({
        freeUSDT: '-',
        freeUSD: '-',
        reservedUSDT: '-',
        reservedUSD: '-',
        netUSDT: '-',
        netUSD: '-',
    });
    const [feeSchedule, setFeeSchedule] = useState(null);
    const [showConfig, setShowConfig] = useState(false);
    const [showQR, setShowQR] = useState(false);
    const [showFeeSchedule, setShowFeeSchedule] = useState(false);

    function hideConfig () {
        setShowConfig(false);
    }

    function hideQR () {
        setShowQR(false);
    }

    function hideFee () {
        setShowFeeSchedule(false);
    }

    function getBalance (signal) {
        api.get('fees/balances', {signal}).then(response => {
            const {balances, conversionsUSD} = response?.data ?? {};
            setFeeBalance({
                freeUSDT: +balances?.USDT?.free,
                freeUSD: balances?.USDT?.free * conversionsUSD?.USDT?.price,
                reservedUSDT: +balances?.USDT?.reserved,
                reservedUSD: balances?.USDT?.reserved * conversionsUSD?.USDT?.price,
                netUSDT: (balances?.USDT?.free - balances?.USDT?.reserved),
                netUSD: (balances?.USDT?.free - balances?.USDT?.reserved) * conversionsUSD?.USDT?.price,
            });
        }).catch(error => {
            if (error.code === 'ERR_CANCELED') {
                return;
            }
            console.error(error);
        });
    }

    function getWallet (signal) {
        setFeeStatus('loading');
        api.get('fees/wallet', {signal}).then(response => {
            setFeeWallet(response.data);
            if (!response.data.enabled) {
                setFeeStatus('disabled');
            } else {
                setFeeStatus('OK');
            }
        }).catch(error => {
            if (error.code === 'ERR_CANCELED') {
                return;
            }

            console.error(error);
            if (error.response?.status === 404) {
                setFeeWallet(null);
                setFeeStatus('missing');
            } else {
                setFeeStatus('unknown');
            }
        });
    }

    function getFeeSchedule (signal) {
        api.get('fees/schedule', {signal}).then(response => {
            setFeeSchedule(response?.data ?? null);
        }).catch(error => {
            if (error.code === 'ERR_CANCELED') {
                return;
            }
            console.error(error);
        });
    }

    function onSubmitted (err, data) {
        if (!err) {
            reloadFeeData();
        }
    }

    function reloadFeeData (signal) {
        getBalance(signal);
        getWallet(signal);
        getFeeSchedule(signal);
    }

    useEffect(() => {
        const controller = new AbortController();

        reloadFeeData(controller.signal);
        const pollInterval = setInterval(() => reloadFeeData(controller.signal), POLLING_INTERVAL_MS);

        return () => {
            controller.abort();
            clearInterval(pollInterval);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {!feeSchedule?.walletless && <>
                <Modal
                    show={showConfig}
                    onHide={hideConfig}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Fee Wallet
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="d-flex justify-content-center">
                        <AjaxForm formId="feeWalletForm" method="POST" path="fees/wallet" submitText={(feeStatus === 'disabled') ? 'Enable' : 'Generate'} hideSubmit={feeStatus === 'OK'}
                                  onSubmitted={onSubmitted} autocomplete="off">
                            <Form.Group className="mb-3" controlId="feeChain">
                                <Form.Label>EVM Chain</Form.Label>
                                <Form.Select name="chainId" aria-label="EVM Chain" defaultValue="42161" disabled>
                                    <option value="42161">Arbitrum One</option>
                                </Form.Select>
                                <Form.Text id="chainHelpBlock" muted>
                                    <FontAwesomeIcon icon={faInfoCircle} />&nbsp;
                                    Paying Arcus fees on Avalanche, or other networks, is not currently supported.
                                </Form.Text>
                            </Form.Group>
                            {feeWallet?.address &&
                                <Form.Group className="mb-3" controlId="feeWalletAddress">
                                    <Form.Label>Wallet Address</Form.Label>
                                    <Form.Control type="text" readOnly disabled value={feeWallet?.address} data-lpignore="true" />
                                </Form.Group>
                            }
                            {(feeWallet && !feeWallet.enabled) &&
                                <Alert variant="warning">
                                    <FontAwesomeIcon icon={faTriangleExclamation} />&nbsp;
                                    This wallet is not enabled.
                                </Alert>
                            }
                        </AjaxForm>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={hideConfig} variant="secondary">Close</Button>
                    </Modal.Footer>
                </Modal>

                <QRCodeModal show={showQR} onHide={hideQR} value={feeWallet?.address} title={`${feeWallet?.friendlyName || 'Arcus Fees'} Wallet Address`} />

                {feeSchedule &&
                    <Modal
                        show={showFeeSchedule}
                        onHide={hideFee}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                Fee Schedule
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {((feeSchedule.tiers?.length === 1) || feeSchedule.newDiscountApplies || feeSchedule.referralDiscountApplies) &&
                                <p>
                                    Basic fee: <NumericFormat displayType="text" decimalScale={2} suffix={'%'} value={feeSchedule.tiers[0].fee * 100} />
                                </p>
                            }
                            {(feeSchedule.tiers?.length > 1) &&
                                <Table striped>
                                    <thead>
                                        <tr>
                                            <th>AUM</th>
                                            <th>Fee</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {feeSchedule.tiers.map((tier, index) =>
                                            <tr key={tier.minAum} className={(tier.minAum === feeSchedule?.tier?.minAum) ? 'current-fee-tier' : ''}>
                                                {(tier.minAum === 0) ?
                                                    <td>&lt; <NumericFormat displayType="text" thousandSeparator={','} decimalScale={0} prefix={'$'} value={feeSchedule.tiers[index + 1].minAum} /></td>
                                                    : <td>&ge; <NumericFormat displayType="text" thousandSeparator={','} decimalScale={0} prefix={'$'} value={tier.minAum} /></td>
                                                }
                                                <td><NumericFormat displayType="text" decimalScale={2} suffix={'%'} value={tier.fee * 100} /></td>
                                            </tr>,
                                        )}
                                    </tbody>
                                </Table>
                            }
                            {feeSchedule.newDiscountApplies &&
                                <p>New user discount: <NumericFormat displayType="text" decimalScale={2} suffix={'%'} value={feeSchedule.newDiscount * 100} />
                                    {feeSchedule.newDiscountDeadline &&
                                        <> until {feeSchedule.newDiscountDeadline}</>
                                    }
                                </p>
                            }
                            {feeSchedule.referralDiscountApplies &&
                                <p>Referral discount: <NumericFormat displayType="text" decimalScale={2} suffix={'%'} value={feeSchedule.referralDiscount * 100} />
                                    {feeSchedule.referralDiscountDeadline &&
                                        <> until {feeSchedule.referralDiscountDeadline}</>
                                    }
                                </p>
                            }
                            {((feeSchedule.tiers?.length > 1) || feeSchedule.newDiscountApplies || feeSchedule.referralDiscountApplies) &&
                                <p>
                                    Current performance fee: <NumericFormat displayType="text" decimalScale={2} suffix={'%'} value={feeSchedule.fee * 100} />
                                </p>
                            }
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={hideFee} variant="secondary">Close</Button>
                        </Modal.Footer>
                    </Modal>
                }

                <Accordion.Item eventKey={eventKey}>
                    <Accordion.Header as="h4">
                        <BalanceAccordionTitle status={feeStatus} okIcon={chainIcons[feeWallet?.chainName]} tooltip={feeWallet?.chainName}>
                            Fee Wallet
                            <NumericFormat displayType="text" thousandSeparator={','} decimalScale={0} prefix={'$'} value={feeBalance.netUSD} />
                        </BalanceAccordionTitle>
                    </Accordion.Header>
                    <Accordion.Body>
                        <CurrencyConversion className="mb-2"
                                            leftTooltip="USDT balance" leftValue={feeBalance.freeUSDT} rightValue={feeBalance.freeUSD}
                                            leftSymbol={<><FontAwesomeIcon icon={faTether} fixedWidth /><FontAwesomeIcon icon={faScaleBalanced} fixedWidth /></>}
                        />
                        <CurrencyConversion className="mb-2"
                                            leftTooltip="USDT reserved for fee payment" leftValue={-feeBalance.reservedUSDT} rightValue={-feeBalance.reservedUSD}
                                            leftSymbol={<><FontAwesomeIcon icon={faTether} fixedWidth /><FontAwesomeIcon icon={faHandHoldingDollar} fixedWidth /></>}
                        />
                        <Form.Group controlId="feeWalletConnection" className="mb-2">
                            <InputGroup>
                                {!feeWallet &&
                                    <HoverTip tooltip="Create Fee wallet">
                                        <Button variant="outline-secondary" onClick={() => setShowConfig(true)}><FontAwesomeIcon icon={faCog} /></Button>
                                    </HoverTip>
                                }
                                {(feeWallet && !feeWallet.enabled) &&
                                    <HoverTip tooltip="This wallet is not enabled.">
                                        <Button variant="warning" onClick={() => setShowConfig(true)}><FontAwesomeIcon icon={faCog} /></Button>
                                    </HoverTip>
                                }
                                <FloatingLabel label={`${feeWallet?.friendlyName || 'Arcus Fees'} Wallet Address`}>
                                    <Form.Control type="text" readOnly value={feeWallet?.address || ''} data-lpignore="true" />
                                </FloatingLabel>
                                <HoverTip tooltip="View as QR code">
                                    <Button variant="outline-secondary" onClick={() => setShowQR(true)} disabled={!feeWallet?.address}><FontAwesomeIcon icon={faQrcode} /></Button>
                                </HoverTip>
                                <ClipboardButton variant="outline-secondary" text={feeWallet?.address} disabled={!feeWallet?.address} />
                            </InputGroup>
                            <Form.Text id="feeWalletHelpBlock" muted>
                                <FontAwesomeIcon icon={faInfoCircle} />&nbsp;
                                Ensure sufficient USDT is deposited to this address on the {feeWallet?.chainName || 'correct'} network to cover Arcus fees.
                            </Form.Text>
                        </Form.Group>
                        {feeSchedule &&
                            <Alert variant="info" className="d-flex justify-content-between">
                                <span>Performance fee: <NumericFormat displayType="text" decimalScale={2} suffix={'%'} value={feeSchedule.fee * 100} /></span>
                                <span><FontAwesomeIcon icon={faMagnifyingGlassDollar} className="pointer" onClick={() => setShowFeeSchedule(true)} /></span>
                            </Alert>
                        }
                    </Accordion.Body>
                </Accordion.Item>
            </>}
        </>
    );
}

export default FeeAccordionItem;
