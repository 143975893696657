
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircleChevronDown, faCircleChevronUp} from '@fortawesome/free-solid-svg-icons';

import './index.css';

function ScrollChevron ({upArrow, scrollRef}) {
    function scrollToReference () {
        scrollRef?.current?.scrollIntoView?.({behavior: 'smooth'});
    }

    return (
        <FontAwesomeIcon
            className={`pointer ${upArrow ? 'scroll-up-icon' : 'scroll-down-icon'}`}
            icon={upArrow ? faCircleChevronUp : faCircleChevronDown}
            size="2xl"
            onClick={scrollToReference}
        />
    );
}

export default ScrollChevron;
