import Button from 'react-bootstrap/Button';
import {useState, useEffect} from 'react';
import {toast} from 'react-toastify';

import {faCircleXmark} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {useAPI} from '../../../../components/APIProvider';
import {useDashboardContext} from '../../Context';
import ConfirmationModal from '../../../../components/ConfirmationModal';

const POLLING_INTERVAL_MS = 30000;

const CloseHedgesButton = (props) => {
    const [show, setShow] = useState(false);
    const [openHedgeMarkets, setOpenHedgeMarkets] = useState([]);
    const [submitError, setSubmitError] = useState(false);
    const {hedgingEnabled, hedgeProvider, setHedgePositions} = useDashboardContext();

    const closeModal = () => setShow(false);
    const showModal = (e) => {
        e.stopPropagation();

        setSubmitError(null);
        setShow(true);
    };

    const {api} = useAPI();

    useEffect(() => {
        const controller = new AbortController();

        getOpenHedges(controller.signal).catch(console.error);
        const pollInterval = setInterval(() => getOpenHedges(controller.signal), POLLING_INTERVAL_MS);

        return () => {
            controller.abort();
            clearInterval(pollInterval);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [api, hedgingEnabled]);

    async function getOpenHedges (signal) {
        try {
            let response = await api({
                method: 'GET',
                url: '/hedging/positions',
                signal,
            });
            let positions = response?.data || [];
            setHedgePositions(positions);
            setOpenHedgeMarkets(positions.filter(p => (p?.size > 0)).map(p => p.symbol));
        } catch (error) {
            console.error(`Failed to get open Hedges: ${error.response?.data?.error || error.message}`);
        }
    }

    const closeHedges = async () => {
        setSubmitError(null);

        try {
            let response = await api({
                method: 'DELETE',
                url: '/hedging/positions',
            });

            setOpenHedgeMarkets([]);
            closeModal();

            let positions = response?.data || [];
            let markets = (positions).map(p => p.symbol);
            if (markets?.length) {
                toast.info(`Closing ${markets.length} positions (${markets.join(', ')}).`);
                return;
            }
            toast.info(`No hedge positions to cancel (cancellation may be in progress).`);
        } catch (error) {
            setSubmitError(error.response?.data?.error || error.message);
        }
    };

    return (
        <>
            {(hedgingEnabled === false) && hedgeProvider?.enabled && !!openHedgeMarkets.length &&
                <div className="text-center">
                    <Button variant="warning" {...props} onClick={showModal}>
                        <FontAwesomeIcon icon={faCircleXmark}></FontAwesomeIcon> Close All Hedges
                    </Button>
                </div>
            }

            <ConfirmationModal showState={show} errorState={submitError} onHide={closeModal} onApprove={closeHedges}>
                <p>
                    Are you sure you want to close all hedge positions?
                </p>
                <p>
                    Note: You will incur trading fees to close your positions.
                </p>
            </ConfirmationModal>
        </>
    );
};

export default CloseHedgesButton;
