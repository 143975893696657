import {faArbitrumOne, faAvalanche, faBinance, faGLP, faOKX, faTether} from './custom-solid-svg-icons';
import {faEthereum} from '@fortawesome/free-brands-svg-icons';
import {faCubes, faDollarSign, faQuestion} from '@fortawesome/free-solid-svg-icons';

export const chainIcons = {
    'Arbitrum One': faArbitrumOne,
    'Avalanche': faAvalanche,
    'Ethereum': faEthereum,
    'null': faCubes,
};

export const currencyIcons = {
    GLP: faGLP,
    ETH: faEthereum,
    WETH: faEthereum,
    USDT: faTether,
    USD: faDollarSign,
    null: faQuestion,
};

export const hedgeProviderIcons = {
    Binance: faBinance,
    OKX: faOKX,
};
