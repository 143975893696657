import {useEffect, useRef, useState} from 'react';

import {useAuth0} from '@auth0/auth0-react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Header from './Header';
import Footer from '../../components/Footer';
import SignupButton from '../../components/SignupButton';
import ScrollChevron from '../../components/ScrollChevron';

import './index.css';

const CryptoLandingPage = () => {
    const HTTP_STATUS_OK = 200;
    const {isAuthenticated} = useAuth0();
    const titleRef = useRef();
    const infographicRef = useRef();
    const learningVideoRef = useRef();
    const [historicalPerformance, setHistoricalPerformance] = useState(null);

    async function getHistoricalPerformance () {
        const response = await fetch('/api/general/historicalPerformance');
        const responseText = await response.text();

        if (response.ok && (response.status === HTTP_STATUS_OK) && responseText) {
            setHistoricalPerformance(responseText);
        }
    }

    useEffect(() => {
        document.documentElement.classList.add('snappy-scrolling');

        getHistoricalPerformance().catch(console.error);

        return () => {
            document.documentElement.classList.remove('snappy-scrolling');
        };
    });

    return (
        <>
            <div>
                <Header />
                <main className="landing-main">
                    <section id="title" style={{background: `url('${process.env.REACT_APP_ASSET_BUCKET}/arcus_landing_bg.jpg') center center/cover no-repeat`}} ref={titleRef}>
                        <div className="landing-title-column">
                            <h3>Your self-custodial, delta-neutral GLP solution</h3>
                            <div className="title-container">
                                <img src={`${process.env.REACT_APP_ASSET_BUCKET}/title_logo.png`} alt="Arcus Yield" />
                                {!isAuthenticated &&
                                    <SignupButton variant="light" className="signup-button rounded-4">Sign Up</SignupButton>
                                }
                            </div>
                            <ScrollChevron scrollRef={infographicRef} />
                        </div>
                    </section>
                    <section id="infographic" className="gradient-background" ref={infographicRef}>
                        <ScrollChevron upArrow={true} scrollRef={titleRef} />
                        <Container className="m-0">
                            <Row className="mb-3">
                                <Col className="text-center">
                                    <h1>Automated GLP Delta Neutral Strategy</h1>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={8}>
                                    <div className="infographic-container">
                                        <img src={`${process.env.REACT_APP_ASSET_BUCKET}/infographic.png`} alt="Infographic" />
                                    </div>
                                </Col>
                                <Col lg={4} className="d-flex flex-column justify-content-center fw-bold fs-4 text">
                                    <ul>
                                        <li>{historicalPerformance || 'Historic performance in excess of 1% per month'}</li>
                                        <li>Earn <a style={{color: 'white'}} href="https://www.binance.com/en/feed/post/65045" target="_blank" rel="noreferrer">GLP yield</a> with the capital risk removed</li>
                                        <li>Automated GLP hedging in <em>your own</em> exchange account</li>
                                    </ul>
                                </Col>
                            </Row>
                            {process.env.REACT_APP_YOUTUBE_WHAT_IS_ARCUS_URL && <ScrollChevron scrollRef={learningVideoRef} />}
                        </Container>
                    </section>
                    {process.env.REACT_APP_YOUTUBE_WHAT_IS_ARCUS_URL &&
                        <section id="learningVideo" className="gradient-background-inverted" ref={learningVideoRef}>
                            <ScrollChevron upArrow={true} scrollRef={infographicRef} />
                            <Container>
                                <Row className="text-center">
                                    <Col>
                                        <div className="youtube-container">
                                            <iframe className="responsive-iframe"
                                                    src={`${process.env.REACT_APP_YOUTUBE_WHAT_IS_ARCUS_URL}`}
                                                    title="How to use Arcus"
                                                    frameBorder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                    allowFullScreen
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </section>
                    }
                </main>
            </div>
            <Footer />
        </>
    );
};

export default CryptoLandingPage;
