import {useEffect, useState} from 'react';

import ReactApexChart from 'react-apexcharts';

import Carousel from 'react-bootstrap/Carousel';

import './carousel.css';

import {useAPI} from '../../../components/APIProvider';

const USD_PRECISION = 2;

function ChartCarousel () {
    const {api} = useAPI();

    useEffect(() => {
        setTimeout(() => {
            getGraphAjaxData().catch(console.error);
        }, 1000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [DailyPnlGraph, setDailyPnLGraph] = useState({
        series: [],
        options: {
            xaxis: {
                categories: [],
            },
        },
    });
    const [CapitalHistoryGraph, setCapitalHistoryGraph] = useState({
        series: [],
        options: {
            xaxis: {
                categories: [],
            },
        },
    });

    const HedgeVsGlpState = {
        series: [],
        options: {
            chart: {
                height: 280,
                type: 'line',
                zoom: {
                    enabled: false,
                },
            },
            title: {
                text: 'Capital History',
                align: 'center',
                style: {
                    color: '#FFF',
                    fontFamily: 'DINPro',
                    fontWeight: 400,
                },
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                curve: 'straight',
            },
            markers: {
                showNullDataPoints: false,
            },
            xaxis: {
                type: 'datetime',
                labels: {
                    rotate: -45,
                    format: 'dd MMM',
                },
                axisTicks: {
                    color: '#FFF',
                },
                tickAmount: 30,
            },
            yaxis: [
                {
                    show: false,
                    seriesName: 'Total Capital (USD)',
                    labels: {
                        formatter: function (y) {
                            if (y) {
                                return `${y.toFixed(USD_PRECISION)} USD`;
                            }
                            return '';
                        },
                        style: {
                            colors: ['#FFF'],
                        },
                    },
                },
                {
                    seriesName: 'Total Capital (USD)',
                    labels: {
                        formatter: function (y) {
                            if (y) {
                                return `${y.toFixed(0)} USD`;
                            }
                            return '';
                        },
                        style: {
                            colors: ['#FFF'],
                        },
                    },
                    axisTicks: {
                        color: '#FFF',
                    },
                },
                {
                    opposite: true,
                    seriesName: 'GLP price (USD)',
                    labels: {
                        formatter: function (y) {
                            if (y) {
                                return `${y.toFixed(USD_PRECISION)} USD`;
                            }
                            return '';
                        },
                        style: {
                            colors: ['#FFF'],
                        },
                    },
                    axisTicks: {
                        color: '#FFF',
                    },
                },
            ],
            legend: {
                labels: {
                    colors: ['#FFF'],
                },
                onItemClick: {
                    toggleDataSeries: false,
                },
            },
            tooltip: {
                enabled: true,
                x: {
                    format: 'dd MMM HH:mm',
                },
                custom: customTooltip,
            },
        },
    };

    const DailyPnlState = {
        series: [],
        options: {
            chart: {
                type: 'line',
                height: 280,
                zoom: {
                    enabled: false,
                },
                stacked: true,
            },
            title: {
                text: 'Daily PnL',
                align: 'center',
                style: {
                    color: '#FFF',
                    fontFamily: 'DINPro',
                    fontWeight: 400,
                },
            },
            dataLabels: {
                enabled: false,
            },
            markers: {
                showNullDataPoints: false,
            },
            yaxis: [
                {
                    seriesName: 'Rewards PnL (USD)',
                    labels: {
                        formatter: function (y) {
                            if (y) {
                                return `${y.toFixed(USD_PRECISION)} USD`;
                            }
                            return '';
                        },
                        style: {
                            colors: ['#FFF'],
                        },
                    },
                },
                {
                    seriesName: 'Rewards PnL (USD)',
                    show: false,
                    labels: {
                        formatter: function (y) {
                            if (y) {
                                return `${y.toFixed(USD_PRECISION)} USD`;
                            }
                            return '';
                        },
                        style: {
                            colors: ['#FFF'],
                        },
                    },
                },
                {
                    seriesName: 'Annualised PnL %',
                    opposite: true,
                    labels: {
                        formatter: function (y) {
                            if (y) {
                                return `${y.toFixed(0)}%`;
                            }
                            return '';
                        },
                        style: {
                            colors: ['#FFF'],
                        },
                    },
                },
            ],
            xaxis: {
                type: 'datetime',
                categories: [],
                labels: {
                    rotate: -45,
                },
                tickAmount: 30,
            },
            legend: {
                labels: {
                    colors: ['#FFF'],
                },
                onItemClick: {
                    toggleDataSeries: false,
                },
            },
            tooltip: {
                enabled: true,
                custom: customTooltip,
            },
        },
    };

    async function getGraphAjaxData () {
        let capitalHistoryResponse = await api.get('performance/capitalHistory');

        let dailyPnLResponse = await api.get('performance/dailyPnL');

        if (capitalHistoryResponse && dailyPnLResponse) {
            formatGraphData(dailyPnLResponse, capitalHistoryResponse);
        }
    }

    function formatGraphData (dailyPnLResponse, capitalHistoryResponse) {
        let rewardsSeries = {
            name: 'Rewards PnL (USD)',
            type: 'bar',
            data: [],
        };
        let capitalSeries = {
            name: 'Capital PnL (USD)',
            type: 'bar',
            data: [],
        };
        let annualisedSeries = {
            name: 'Annualised PnL %',
            type: 'line',
            data: [],
        };

        let dailyPnlXAxisCategories = [];

        for (let item of dailyPnLResponse.data) {
            rewardsSeries.data.push(item.rewardsPnL);
            capitalSeries.data.push(item.capitalPnL);
            annualisedSeries.data.push(((item.annualizedPnL === null) ? null : item.annualizedPnL * 100));
            dailyPnlXAxisCategories.push(item.date);
        }

        DailyPnlState.series = [rewardsSeries, capitalSeries, annualisedSeries];
        DailyPnlState.options.xaxis.categories = dailyPnlXAxisCategories;

        let hedgeCapitalSeries = {
            name: 'Hedge Capital (USD)',
            type: 'line',
            data: [],
        };
        let glpSeries = {
            name: 'GLP price (USD)',
            type: 'line',
            data: [],
        };
        let totalCapitalSeries = {
            name: 'Total Capital (USD)',
            type: 'line',
            data: [],
        };

        let hedgeVsGlpXAxisCategories = [];

        for (let item of capitalHistoryResponse.data) {
            hedgeCapitalSeries.data.push(item.hedgeCapital);
            glpSeries.data.push(item.glpPrice);
            totalCapitalSeries.data.push(item.totalCapital);
            hedgeVsGlpXAxisCategories.push(item.timestamp);
        }

        HedgeVsGlpState.series = [hedgeCapitalSeries, totalCapitalSeries, glpSeries];
        HedgeVsGlpState.options.xaxis.categories = hedgeVsGlpXAxisCategories;

        setCapitalHistoryGraph(HedgeVsGlpState);
        setDailyPnLGraph(DailyPnlState);
    }

    function Graphs () {
        return (
            <>
                {!!(CapitalHistoryGraph.series?.length || DailyPnlGraph.series?.length) &&
                    <div className="carouselWrapper p-3 container">
                        <Carousel className="w-100" indicators={false}>
                            {!!CapitalHistoryGraph.series?.length &&
                                <Carousel.Item className="carouselContent">
                                    <div className="carouselGraph" id="HedgeVsGlp">
                                        <ReactApexChart options={CapitalHistoryGraph.options} series={CapitalHistoryGraph.series} type="line" height={280} />
                                    </div>
                                </Carousel.Item>
                            }
                            {!!DailyPnlGraph.series?.length &&
                                <Carousel.Item className="carouselContent">
                                    <div className="carouselGraph" id="DailyPnl">
                                        <ReactApexChart options={DailyPnlGraph.options} series={DailyPnlGraph.series} type="line" height={280} />
                                    </div>
                                </Carousel.Item>
                            }
                        </Carousel>
                    </div>
                }
            </>
        );
    }

    return (
        <>
            <Graphs />
        </>
    );
}

function customTooltip ({series, seriesIndex, dataPointIndex, w}) {
    let allNull = true;
    for (let s of series) {
        if (s[dataPointIndex] !== null) {
            allNull = false;
            break;
        }
    }

    let title = w.globals.tooltip.tooltipTitle.outerHTML;
    if (allNull) {
        let blankText = `<div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;"><div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
No performance data here because hedging was paused.
</div></div>`;
        return title + blankText;
    }

    let items = '';
    w.globals.tooltip.ttItems.forEach(x => {
        items = items + x.outerHTML;
    });
    return title + items;
}

export default ChartCarousel;
