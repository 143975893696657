import './App.scss';
import 'react-toastify/dist/ReactToastify.css';

import {Routes, Route} from 'react-router-dom';
import {useAuth0} from '@auth0/auth0-react';
import {ToastContainer, Flip} from 'react-toastify';
import {config as fontAwesomeConfig} from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';

import Landing from './pages/Landing';
import Dashboard from './pages/Dashboard';

import AuthenticationGuard from './components/AuthenticationGuard';
import PageLoader from './components/PageLoader';
import Redirect from './components/Redirect';
import Referral from './components/Referral';

import Auth0Callback from './pages/Auth0Callback';
import Profile from './pages/Profile';

function App () {
    const {isLoading} = useAuth0();

    /*
     * By default fontawesome will create an inline <style> on the page, which
     * violates CSP when the style-src directive does not include 'unsafe-inline'.
     * Including 'unsafe-inline' is bad for security though.  We can override this
     * behaviour by setting autoAddCss = false, and manually importing the CSS file above.
     * https://fontawesome.com/v5/docs/web/other-topics/security#example-when-using-npm-packages-fontawesome-svg-core
     */
    fontAwesomeConfig.autoAddCss = false;

    if (isLoading) {
        return (
            <PageLoader />
        );
    }

    return (
        <>
            <Routes>
                <Route exact path="/" element={<Landing />} />
                <Route exact path="/terms" element={<Redirect to={`${process.env.REACT_APP_ASSET_BUCKET}/terms.pdf`} />} />
                <Route exact path="/privacypolicy" element={<Redirect to={`${process.env.REACT_APP_ASSET_BUCKET}/privacy.pdf`} />} />
                <Route exact path="/auth0" element={<Auth0Callback />} />
                <Route exact path="/referral" element={<Referral />} />
                <Route exact path="/signup" element={<AuthenticationGuard component={Profile} isSignUp={true} />} />
                <Route exact path="/profile" element={<AuthenticationGuard component={Profile} isSignUp={false} />} />
                <Route exact path="/dashboard" element={<AuthenticationGuard component={Dashboard} />} />
                <Route exact path="/*" element={<Redirect to="/dashboard" />} />
            </Routes>
            <ToastContainer theme="dark" transition={Flip} autoClose={2000} newestOnTop />
        </>
    );
}

export default App;
