import {forwardRef} from 'react';

import InputGroup from 'react-bootstrap/InputGroup';
import HoverTip from '../HoverTip';
import {NumericFormat} from 'react-number-format';
import Form from 'react-bootstrap/Form';
import {faDollarSign} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const CurrencyConversion = forwardRef(function CurrencyConversion ({leftValue, leftSymbol, leftTooltip, leftDecimals = 8, rightValue, rightSymbol, rightDecimals = 2, rightTooltip, ...props}, ref) {
    return (
        <InputGroup {...props} ref={ref}>
            <HoverTip tooltip={leftTooltip}><InputGroup.Text>{leftSymbol}</InputGroup.Text></HoverTip>
            <NumericFormat value={leftValue} decimalScale={leftDecimals} customInput={Form.Control} readOnly />
            <InputGroup.Text>&asymp;</InputGroup.Text>
            <NumericFormat value={rightValue} decimalScale={rightDecimals} customInput={Form.Control} readOnly />
            <HoverTip tooltip={rightTooltip ?? 'USD'}><InputGroup.Text>{rightSymbol ?? <FontAwesomeIcon icon={faDollarSign} fixedWidth />}</InputGroup.Text></HoverTip>
        </InputGroup>
    );
});

export default CurrencyConversion;
