import {useEffect, useState} from 'react';

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faWarning} from '@fortawesome/free-solid-svg-icons';

import PageLoader from '../../components/PageLoader';

import './index.css';
import {useAuth0} from '@auth0/auth0-react';

const Auth0Callback = () => {
    let [auth0Error, setAuth0Error] = useState(null);
    let {error, logout} = useAuth0();

    useEffect(() => {
        if (!error) {
            return;
        }

        let description = error.message;
        if (error.message.startsWith('{') && error.message.includes('change your password')) {
            try {
                description = JSON.parse(error.message);
                description.passwordExpired = true;
            } catch (err) {
                console.error(err);
            }
        }

        setAuth0Error(description);
    }, [error]);

    function returnToArcus () {
        logout({
            logoutParams: {
                returnTo: window.location.origin,
            },
        });
    }

    return (
        <>
            {!auth0Error &&
                <PageLoader />
            }
            {auth0Error &&
                <main id="error" style={{background: `url('${process.env.REACT_APP_ASSET_BUCKET}/arcus_landing_bg.jpg') center center/cover no-repeat`}}>
                    <div className="d-flex justify-content-center align-items-center vh-100">
                        <Card
                            bg="dark"
                            text="light"
                            className="mb-2 text-center card-auth"
                        >
                            <Card.Header><h5>Authentication Error</h5><FontAwesomeIcon icon={faWarning} size="7x" className="text-warning" /></Card.Header>
                            <Card.Body>
                                {auth0Error.passwordExpired &&
                                    <>
                                        <p>
                                            {!!auth0Error.passwordPolicyLastUpdate &&
                                                // eslint-disable-next-line max-len
                                                <span>Your password has expired because it has not been changed since the last password policy update on {auth0Error.passwordPolicyLastUpdate}.&nbsp;</span>
                                            }
                                            {!!auth0Error.daysSinceLastPasswordChange &&
                                                <span>Your password has expired because it has not been changed in {auth0Error.daysSinceLastPasswordChange} days.&nbsp;</span>
                                            }
                                            {!!auth0Error.maxDaysOfUnchangedPassword &&
                                                <span>Your password needs to be changed at least every {auth0Error.maxDaysOfUnchangedPassword} days.&nbsp;</span>
                                            }
                                            {auth0Error.lastPasswordChange &&
                                                <span>Your password was last updated {auth0Error.lastPasswordChange}.&nbsp;</span>
                                            }
                                        </p>
                                        <p>A password reset email should be on its way to your inbox at {auth0Error.email}. Please follow the steps to set a new password, after which it should be
                                            possible to log in.</p>
                                        <p>We apologize for any inconvenience, but this measure is in place to increase the security of all our users&apos; accounts.</p>
                                    </>
                                }
                                {!auth0Error.passwordExpired &&
                                    <p>{auth0Error}</p>
                                }
                            </Card.Body>
                            <Card.Footer>
                                <Button variant="primary" onClick={returnToArcus}>Back to Arcus</Button>
                            </Card.Footer>
                        </Card>
                    </div>
                </main>
            }
        </>
    );
};

export default Auth0Callback;
