import {Link} from 'react-router-dom';

import {useAuth0} from '@auth0/auth0-react';

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

import UserDropdown from './UserDropdown';
import HelpDropdown from './HelpDropdown';

import './index.css';

const Header = () => {
    const {isAuthenticated} = useAuth0();

    return (
        <Navbar bg="dark" variant="dark" className="transparent-bg p-0">
            <Container className="ms-0">
                <Navbar.Brand as={Link} to="/" className="p-0"><img src={`${process.env.REACT_APP_ASSET_BUCKET}/logo.png`} alt="Arcus Yield" height="60" /></Navbar.Brand>
            </Container>
            <Nav className="me-3 justify-content-end">
                {isAuthenticated && (
                    <>
                        {(window.location.pathname !== '/dashboard') && (
                            <Nav.Link as={Link} to="/dashboard"><Button variant="outline-light">Dashboard</Button></Nav.Link>
                        )}
                        <HelpDropdown />
                        <UserDropdown />
                    </>
                )}
            </Nav>
        </Navbar>
    );
};

export default Header;
