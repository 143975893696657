import {Auth0Provider} from '@auth0/auth0-react';
import {useNavigate} from 'react-router-dom';

import {APIProvider} from '../APIProvider';

/*
 * Provide auth0 context with the ability to navigate to the user's originally
 * intended URL after they've logged in.
 * See: https://developer.auth0.com/resources/guides/spa/react/basic-authentication
 */

const Auth0ProviderWithNavigate = ({children}) => {
    const navigate = useNavigate();

    const domain = process.env.REACT_APP_AUTH0_DOMAIN;
    const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
    const redirectPath = process.env.REACT_APP_AUTH0_CALLBACK_PATH;
    const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

    const onRedirectCallback = (appState, user) => {
        const destination = appState?.returnTo || window.location.pathname;
        if (!user['arcus_signup_complete']) {
            if (destination.startsWith('/signup')) {
                navigate(destination);
            } else {
                navigate('/signup');
            }
        } else {
            if (destination.startsWith('/signup')) {
                if (process.env.REACT_APP_YOUTUBE_HOW_TO_ARCUS_URL) {
                    navigate('/dashboard?showHowTo=true');
                } else {
                    navigate('/dashboard');
                }
            } else {
                navigate(destination);
            }
        }
    };

    if (!(domain && clientId && redirectPath && audience)) {
        return null;
    }

    return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            authorizationParams={{
                audience: new URL(audience).toString(),
                // eslint-disable-next-line camelcase
                redirect_uri: new URL(redirectPath, window.location.origin).toString(),
            }}
            onRedirectCallback={onRedirectCallback}
        >
            <APIProvider>
                {children}
            </APIProvider>
        </Auth0Provider>
    );
};

export default Auth0ProviderWithNavigate;
