import {useAuth0} from '@auth0/auth0-react';
import Button from 'react-bootstrap/Button';

const LoginButton = (props) => {
    const {children, ...otherProps} = props;
    const {loginWithRedirect} = useAuth0();

    const handleLogin = async () => {
        await loginWithRedirect({
            appState: {
                returnTo: '/dashboard',
            },
            authorizationParams: {
                /*
                 * Using prompt: 'login' resets to the start of the login process
                 * when the user clicks our login button, otherwise if they previously
                 * reached the MFA prompt it seems to automatically jump ahead
                 * to that every time.
                 * https://community.auth0.com/t/classic-universal-login-stuck-on-mfa-page-sms/42025
                 */
                prompt: 'login',
            },
        });
    };

    return (
        <Button className="button-login" {...otherProps} onClick={handleLogin}>
            {children}
        </Button>
    );
};

export default LoginButton;
