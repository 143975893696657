import Button from 'react-bootstrap/Button';
import {toast} from 'react-toastify';
import {faClipboard} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import HoverTip from '../HoverTip';

function ClipboardButton ({text, tooltip, elementType, appendIcon = true, children, ...otherProps}) {
    function onClick () {
        navigator.clipboard.writeText(text).then(() => {
            toast.info(`Copied to clipboard: ${text}`);
        }).catch(console.error);
    }

    const ElementType = elementType || Button;

    return (
        <>
            <HoverTip tooltip={tooltip || 'Copy to clipboard'}>
                <ElementType onClick={onClick} {...otherProps}>
                    {children}
                    {!!appendIcon &&
                        <FontAwesomeIcon icon={faClipboard} />
                    }
                </ElementType>
            </HoverTip>
        </>
    );
}

export default ClipboardButton;
