import {useState} from 'react';

import NavDropdown from 'react-bootstrap/NavDropdown';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircleQuestion} from '@fortawesome/free-solid-svg-icons';
import {faYoutube} from '@fortawesome/free-brands-svg-icons';

import HowToModal from '../../HowToModal';

function HelpDropdown () {
    const [showHowToModal, setShowHowToModal] = useState(false);

    function showModal () {
        setShowHowToModal(true);
    }

    return (
        <>
            <HowToModal showState={showHowToModal} setShowState={setShowHowToModal} />
            <NavDropdown
                id="headerUserDropdown"
                align="end"
                menuVariant="dark"
                renderMenuOnMount={true}
                title={<FontAwesomeIcon icon={faCircleQuestion} size="2x" />}
                className="p-1 nav-light">
                {process.env.REACT_APP_YOUTUBE_HOW_TO_ARCUS_URL &&
                    <NavDropdown.Item onClick={showModal}><FontAwesomeIcon icon={faYoutube} />&nbsp;How To</NavDropdown.Item>
                }
                <NavDropdown.Item href="https://support.arcusyield.com/hc/en-us/sections/9109504355101-PRODUCT-FAQ" target="_blank" rel="noreferrer">FAQ</NavDropdown.Item>
            </NavDropdown>
        </>
    );
}

export default HelpDropdown;
