import {withAuthenticationRequired} from '@auth0/auth0-react';

import {withSignupRequired} from '../SignupGuard';
import PageLoader from '../PageLoader';

const AuthenticationGuard = ({component, ...props}) => {
    if (!props.isSignUp) {
        component = withSignupRequired(component);
    }

    const Component = withAuthenticationRequired(component, {
        onRedirecting: () => (
            <PageLoader />
        ),
    });

    return <Component {...props} />;
};

export default AuthenticationGuard;
