import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTriangleExclamation} from '@fortawesome/free-solid-svg-icons';

function ConfirmationModal ({showState, errorState, onHide, onApprove, title = 'Confirmation', children}) {
    return (
        <Modal
            show={showState}
            onHide={onHide}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onClick={(e) => {
                e.stopPropagation();
            }}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {children}
                {errorState &&
                    <Alert variant="danger">
                        <FontAwesomeIcon icon={faTriangleExclamation} /> {errorState}
                    </Alert>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>Cancel</Button>
                <Button variant="primary" onClick={onApprove}>Yes</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ConfirmationModal;
