import {useEffect, useRef, useState} from 'react';

import Container from 'react-bootstrap/Container';
import ListGroup from 'react-bootstrap/ListGroup';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTriangleExclamation} from '@fortawesome/free-solid-svg-icons';

import {useAPI} from '../../../components/APIProvider';
import {useDashboardContext} from '../Context';

import './StopStartBtn.css';
import Lottie from 'lottie-react';
import electrones from '../../../src/lottie/electrones-transparent.json';

const enabledText = {
    null: 'currently unavailable on this account',
    true: 'ON',
    false: 'OFF',
};

const POLLING_INTERVAL_MS = 30000;

function StopStartBtn () {
    const lottieRef = useRef();

    const {api} = useAPI();
    const {hedgingEnabled, setHedgingEnabled, hedgeProvider, glpWallet} = useDashboardContext();
    const [noHedgeReasons, setNoHedgeReasons] = useState([]);

    const BUTTON_CLASS_LOOKUP = {
        undefined: 'disabledBtn',
        null: 'disabledBtn',
        false: 'hedgingOffBtn',
        true: 'hedgingOnBtn',
    };
    const HR_CLASS_LOOKUP = {
        undefined: 'disabledHr',
        null: 'disabledHr',
        false: 'hedgingOffHr',
        true: 'hedgingOnHr',
    };
    const START_TEXT_CLASS_LOOKUP = {
        undefined: 'BtnActiveText',
        null: 'BtnActiveText',
        false: 'BtnActiveText',
        true: '',
    };
    const STOP_TEXT_CLASS_LOOKUP = {
        undefined: '',
        null: '',
        false: '',
        true: 'BtnActiveText',
    };

    useEffect(() => {
        const controller = new AbortController();
        getEnabled(controller.signal);

        const pollInterval = setInterval(() => getEnabled(controller.signal), POLLING_INTERVAL_MS);

        return () => {
            controller.abort();
            clearInterval(pollInterval);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hedgeProvider, glpWallet]);

    // to enable lottie animation
    useEffect(() => {
        if (hedgingEnabled === undefined || hedgingEnabled === null) {
            if (lottieRef.current) {
                lottieRef.current.stop();
            }
        } else if (hedgingEnabled === false) {
            if (lottieRef.current) {
                lottieRef.current.stop();
            }
        }
        if (hedgingEnabled) {
            if (lottieRef.current) {
                lottieRef.current.play();
            }
        }
    }, [hedgingEnabled]);

    function getEnabled (signal) {
        api.get('hedging/enabled', {signal}).then(response => {
            setHedgingEnabled(response.data.enabled);
            setNoHedgeReasons(response.data.reasons || []);
        }).catch(error => {
            if (error.code === 'ERR_CANCELED') {
                return;
            }
            console.error(error);
        });
    }

    function postEnabled (newState) {
        api.post('hedging/enabled', {
            enabled: newState,
        }).then(response => {
            setHedgingEnabled(response.data.enabled);
            setNoHedgeReasons([]);
        }).catch(error => {
            console.error(error);
        });
    }

    return (
        <>
            <span className="d-block text-center">
                <div className="btnContainer">
                    <button className={`StopStartBtn ${BUTTON_CLASS_LOOKUP[hedgingEnabled]}`}
                            onClick={() => {
                                if (hedgingEnabled === null) {
                                    return;
                                }
                                postEnabled(!hedgingEnabled);
                            }}>
                        <span className={START_TEXT_CLASS_LOOKUP[hedgingEnabled]}>START</span>
                        <hr className={HR_CLASS_LOOKUP[hedgingEnabled]} />
                        <span className={STOP_TEXT_CLASS_LOOKUP[hedgingEnabled]}>STOP</span>
                    </button>
                </div>

                <Lottie id="electronesLottieDiv" lottieRef={lottieRef} animationData={electrones} loop={true} />

                <div className="bottomText">
                    <br />Arcus automatic hedging of GLP exposure is {enabledText[hedgingEnabled] ?? '???'}.
                    {!!noHedgeReasons?.length &&
                        <Container className="pt-3 pb-3">
                            <ListGroup className="d-inline-block w-auto">
                                <ListGroup.Item variant="warning">
                                    <FontAwesomeIcon icon={faTriangleExclamation} />&nbsp;
                                    The following problem(s) may still be preventing automated hedging:
                                </ListGroup.Item>
                                {noHedgeReasons.map((reason) =>
                                    <ListGroup.Item key={reason.flag}>
                                        {reason.reason}&nbsp;
                                        {reason.faqLink && <a href={reason.faqLink} target="_blank" rel="noreferrer">More info.</a>}
                                    </ListGroup.Item>,
                                )}
                            </ListGroup>
                        </Container>
                    }
                </div>
            </span>
        </>
    );
}

export default StopStartBtn;
