import {useState} from 'react';
import Button from 'react-bootstrap/Button';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircleNotch, faCircleQuestion, faTriangleExclamation, faScaleBalanced} from '@fortawesome/free-solid-svg-icons';

import HoverTip from '../../../../components/HoverTip';
import HowToModal from '../../../../components/HowToModal';

// eslint-disable-next-line no-shadow
function BalanceAccordionTitle ({status, okIcon = faScaleBalanced, tooltip, children}) {
    const [showHowToModal, setShowHowToModal] = useState(false);

    function showModal () {
        setShowHowToModal(true);
    }

    return (
        <>
            <HowToModal showState={showHowToModal} setShowState={setShowHowToModal} />
            <div className="balance-row">
                {(status === 'loading') &&
                    <HoverTip tooltip="Loading...">
                        <FontAwesomeIcon icon={faCircleNotch} fixedWidth size="xl" spin />
                    </HoverTip>
                }
                {(status === 'unknown') &&
                    <HoverTip tooltip="Failed to load status - check your internet connection?">
                        <FontAwesomeIcon icon={faCircleQuestion} fixedWidth size="xl" />
                    </HoverTip>
                }
                {(status === 'missing') &&
                    <HoverTip tooltip="Not configured.">
                        <FontAwesomeIcon icon={faTriangleExclamation} fixedWidth size="xl" className="text-warning" beat />
                    </HoverTip>
                }
                {(status === 'disabled') &&
                    <HoverTip tooltip="Disabled.">
                        <FontAwesomeIcon icon={faTriangleExclamation} fixedWidth size="xl" className="text-warning" beat />
                    </HoverTip>
                }
                {(status === 'OK') &&
                    <HoverTip tooltip={tooltip}>
                        <FontAwesomeIcon icon={okIcon} fixedWidth size="xl" className="brand-colour" />
                    </HoverTip>
                }
                {children}
                {(status === 'missing') && process.env.REACT_APP_YOUTUBE_HOW_TO_ARCUS_URL &&
                    <Button variant="link" onClick={showModal}>Please see the how-to guide on setting up your Arcus account</Button>
                }
            </div>
        </>
    );
}

export default BalanceAccordionTitle;
