/**
 * Simple linear gradient between two colours.
 * @param {string} start - RGB hex string for the first colour, e.g. '#023CF8'
 * @param {string} end - RGB hex string for the last colour
 * @param {integer} n - the number of colours to return
 * @returns {string[]}
 */
function colourGradient (start, end, n = 2) {
    const startRGB = extractRGB(start);
    const endRGB = extractRGB(end);

    const steps = n - 1;
    const redStep = (endRGB.red - startRGB.red) / steps;
    const greenStep = (endRGB.green - startRGB.green) / steps;
    const blueStep = (endRGB.blue - startRGB.blue) / steps;

    const result = [start];
    const rgb = startRGB;
    for (let i = 1; i < steps; ++i) {
        rgb.red += redStep;
        rgb.green += greenStep;
        rgb.blue += blueStep;
        result.push(encodeRGB(rgb));
    }
    result.push(end);

    return result;
}

function extractRGB (hexString) {
    hexString = hexString.replace('#', '');
    /* eslint-disable no-magic-numbers */
    const red = parseInt(hexString.slice(0, 2), 16);
    const green = parseInt(hexString.slice(2, 4), 16);
    const blue = parseInt(hexString.slice(4, 6), 16);
    /* eslint-enable no-magic-numbers */

    return {
        red,
        green,
        blue,
    };
}

function encodeRGB ({red, green, blue}) {
    return `#${hex(red)}${hex(green)}${hex(blue)}`;
}

function hex (val) {
    // eslint-disable-next-line no-magic-numbers
    return Math.round(val).toString(16).padStart(2, 0);
}

export default colourGradient;
