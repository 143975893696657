let build = {deployversion: ''};

try {
    // eslint-disable-next-line global-require
    build = require('../.build.json');
} catch (err) {
    console.error(err);
}

export default build.deployversion;
