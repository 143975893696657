import {useEffect, useState} from 'react';

import ReactApexChart from 'react-apexcharts';
import {numericFormatter} from 'react-number-format';

import colourGradient from '../../../../src/colours/gradient';

const AMOUNT_PRECISION = 4;

const CollateralChart = ({collateral, ...props}) => {
    const formatter = (val, opt) => {
        const series = opt.w.config.series[opt.seriesIndex];
        const label = series.name;
        // stackType: '100%' gives us the fraction as val, but we want to show the underlying value.
        val = series.data[opt.dataPointIndex].v ?? series.data[opt.dataPointIndex].y;
        const amount = numericFormatter(val?.toFixed?.(AMOUNT_PRECISION), {displayType: 'text', thousandSeparator: ',', decimalScale: 2, prefix: '$'});
        return `${label}:  ${amount}`;
    };
    const [series, setSeries] = useState([]);
    const [options] = useState({
        chart: {
            type: 'bar',
            stacked: true,
            stackType: '100%',
            toolbar: {show: false},
            offsetX: 0,
            offsetY: 0,
            parentHeightOffset: 0,
            background: 'transparent',
        },
        plotOptions: {
            bar: {
                horizontal: true,
            },
        },
        // eslint-disable-next-line no-magic-numbers
        colors: colourGradient('#1b5bad', '#16c2b4', 3),
        theme: {
            mode: 'dark',
        },
        grid: {
            show: false,
            padding: {
                top: -20,
                bottom: 0,
                left: 12,
                right: 12,
            },
        },
        dataLabels: {
            enabled: true,
            style: {
                colors: ['#fff'],
            },
            formatter,
            dropShadow: {
                enabled: true,
            },
        },
        stroke: {
            show: true,
            width: 0.5,
            colors: ['#fff'],
        },
        xaxis: {
            labels: {show: false},
            axisBorder: {show: false},
            axisTicks: {show: false},
        },
        yaxis: {
            labels: {show: false},
            axisBorder: {show: false},
            axisTicks: {show: false},
        },
        legend: {
            show: false,
        },
        tooltip: {
            enabled: true,
            theme: 'dark',
            followCursor: true,
            x: {
                formatter: (val, opt) => opt.w.config.series[opt.seriesIndex].data[opt.dataPointIndex].x,
            },
            y: {
                title: {
                    formatter: () => '',
                },
                formatter: (val, opt) => {
                    const thisSeries = opt.w.config.series[opt.seriesIndex];
                    // stackType: '100%' gives us the fraction as val, but we want to show the underlying value.
                    val = thisSeries.data[opt.dataPointIndex].v ?? thisSeries.data[opt.dataPointIndex].y;
                    return numericFormatter(val?.toFixed?.(AMOUNT_PRECISION), {displayType: 'text', thousandSeparator: ',', decimalScale: 2, prefix: '$'});
                },
            },
        },
    });

    useEffect(() => {
        setSeries([{
            name: 'Used',
            data: [{
                x: 'Used (Margin)',
                y: collateral.used,
            }],
        }, {
            name: 'Open',
            data: [{
                x: 'Unrealized Profit/Loss',
                y: Math.abs(collateral.openPnL),
                v: collateral.openPnL,
            }],
        }, {
            name: 'Free',
            data: [{
                x: 'Available Balance',
                y: collateral.free,
            }],
        }]);
    }, [collateral]);

    return (
        <div>
            <ReactApexChart options={options} series={series} type="bar" height={55} />
        </div>
    );
};

export default CollateralChart;
