import {Fragment, useEffect, useState, useRef} from 'react';

import Accordion from 'react-bootstrap/Accordion';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Modal from 'react-bootstrap/Modal';

import {NumericFormat} from 'react-number-format';

import {faTriangleExclamation, faInfoCircle, faCog, faExternalLink} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import HedgePositionsChart from './positions-chart';
import CollateralChart from './collateral-chart';
import BalanceAccordionTitle from '../BalanceAccordionTitle';
import {useAPI} from '../../../../components/APIProvider';
import AjaxForm from '../../../../components/AjaxForm';
import HoverTip from '../../../../components/HoverTip';
import ClipboardButton from '../../../../components/ClipboardButton';
import CloseHedgesButton from '../CloseHedgesButton';

import {hedgeProviderIcons} from '../../../../src/fontawesome/icon-groups';

import {useDashboardContext} from '../../Context';

const POLLING_INTERVAL_MS = 30000;

const KEY_ABBR_CHARS = 5;
const SECRET_MIN_LENGTH = 10;

function HedgeAccordionItem ({eventKey}) {
    const {api} = useAPI();
    const [hedgeStatus, setHedgeStatus] = useState('loading');
    const [hedgeProviders, setHedgeProviders] = useState([]);
    const [selectedProvider, setSelectedProvider] = useState('');
    const [configShown, setConfigShown] = useState(false);
    const shownRef = useRef(configShown);
    const [hedgeValid, setHedgeValid] = useState(true);
    const {hedgeProvider, setHedgeProvider, hedgingEnabled, hedgeCollateral, setHedgeCollateral, hedgePositions} = useDashboardContext();

    function hedgeSecretChanged (e) {
        let secretLength = e.currentTarget.value.length;
        setHedgeValid((secretLength < 1) || (secretLength >= SECRET_MIN_LENGTH));
    }

    function showConfig () {
        setConfigShown(true);
        shownRef.current = true;
    }

    function hideConfig () {
        setConfigShown(false);
        shownRef.current = false;
    }

    function getCollateral (signal) {
        api.get('hedging/collateral', {signal}).then(response => {
            setHedgeCollateral({
                total: +response.data?.total + +response.data?.openPnL,
                free: +response.data?.free,
                used: +response.data?.used,
                openPnL: +response.data?.openPnL,
            });
        }).catch(error => {
            if (error.code === 'ERR_CANCELED') {
                return;
            }
            console.error(error);
        });
    }

    function getProviders (signal) {
        setHedgeStatus('loading');
        api.get('hedging/providers', {signal}).then(response => {
            for (let provider of response.data) {
                if (provider.apiKey) {
                    provider.abbrKey = `${provider.apiKey.slice(0, KEY_ABBR_CHARS)}...${provider.apiKey.slice(-KEY_ABBR_CHARS)}`;
                }
            }

            setHedgeProviders(response.data);
            const selected = response.data.find(p => p.selected);
            if (selected) {
                setHedgeProvider(selected);
                setSelectedProvider(selected.name);
                if (!selected.enabled) {
                    setHedgeStatus('disabled');
                } else {
                    setHedgeStatus('OK');
                }
            } else {
                setHedgeStatus('missing');
                setSelectedProvider('');
            }
        }).catch(error => {
            if (error.code === 'ERR_CANCELED') {
                return;
            }
            console.error(error);
            setHedgeStatus('unknown');
        });
    }

    function onSubmitted (err, data) {
        if (!err) {
            reloadHedgingData();
        }
    }

    function reloadHedgingData (signal) {
        getCollateral(signal);
        getProviders(signal);
    }

    useEffect(() => {
        const controller = new AbortController();

        reloadHedgingData(controller.signal);
        const pollInterval = setInterval(() => {
            if (shownRef.current) {
                return;
            }
            reloadHedgingData(controller.signal);
        }, POLLING_INTERVAL_MS);

        return () => {
            controller.abort();
            clearInterval(pollInterval);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Modal
                show={configShown}
                onHide={hideConfig}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Hedge Provider
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="d-flex justify-content-center">
                    <AjaxForm formId="hedgeProviderForm" method="POST" path="hedging/provider" submitText="Save & Enable" onSubmitted={onSubmitted} valid={hedgeValid}>
                        <Form.Group controlId="hedgeProviderName">
                            <Form.Label>Provider</Form.Label>
                            <Form.Select aria-label="Provider" value={selectedProvider} onChange={(e) => setSelectedProvider(e.currentTarget.value)} disabled={hedgingEnabled}>
                                <option value="" disabled>Select provider</option>
                                {hedgeProviders.map(p => <option key={p.name} value={p.name}>{p.niceName}</option>)}
                            </Form.Select>
                            <input type="hidden" name="name" value={selectedProvider} />
                            <Form.Text id="apiKeyHelpBlock" muted>
                                <FontAwesomeIcon icon={faInfoCircle} />&nbsp;
                                A centralized exchange with perpetual futures markets, suitable for hedging exposure to the
                                crypto-currencies composing your GLP balance. It will be necessary to create your own account
                                there and complete the necessary KYC to trade these derivatives.
                            </Form.Text>
                        </Form.Group>
                        {hedgeProviders.map(provider =>
                            <Fragment key={provider.name}>
                                {(provider.name === selectedProvider) &&
                                    <>
                                        {(!provider.id && (provider.referral.link || provider.links.signup || provider.links.main)) &&
                                            <Alert variant="info" className="mb-2 mt-2">
                                                Don&apos;t have a {provider.niceName} futures account yet?&nbsp;
                                                <a href={provider.referral.link || provider.links.signup || provider.links.main} target="_blank" rel="noreferrer">
                                                    Sign up <FontAwesomeIcon icon={faExternalLink} size="xs" />
                                                </a>
                                                {(!!provider.referral.code) &&
                                                    <ClipboardButton text={provider.referral.code} tooltip={provider.referral.why} className="pointer" elementType="span">
                                                        &nbsp;using referral code <strong>{provider.referral.code}</strong>&nbsp;
                                                    </ClipboardButton>
                                                }
                                                .
                                                {!!(provider.links.referralInfo && provider.referral.code) &&
                                                    <>
                                                        <br /><small><a href={provider.links.referralInfo} target="_blank" rel="noreferrer" className="text-muted">
                                                            More info <FontAwesomeIcon icon={faExternalLink} size="xs" />
                                                        </a></small>
                                                    </>
                                                }
                                            </Alert>
                                        }

                                        {!!provider.links.apiSetup &&
                                            <small><a href={provider.links.apiSetup} target="_blank" rel="noreferrer">
                                                Manage API keys at {provider.niceName}&nbsp;<FontAwesomeIcon icon={faExternalLink} size="xs" />
                                            </a></small>
                                        }

                                        <Form.Group className="mt-3" controlId="hedgeProviderAPIKey">
                                            <Form.Label>API Key</Form.Label>
                                            <Form.Control name="apiKey" type="text" required data-lpignore="true"
                                                          placeholder="Enter API Key" defaultValue={provider?.apiKey} readOnly={hedgingEnabled}
                                            />
                                            <Form.Text id="apiKeyHelpBlock" muted>
                                                <FontAwesomeIcon icon={faInfoCircle} />&nbsp;
                                                The API key and secret will be stored with encryption, and only decrypted when we need
                                                to do API requests to the hedge provider. It is advisable to restrict the key permissions
                                                to the minimum required for us to manage the hedging.
                                            </Form.Text>
                                        </Form.Group>
                                        <Form.Group className="mt-3" controlId="hedgeProviderAPISecret">
                                            <Form.Label>API Secret</Form.Label>
                                            <Form.Control name="apiSecret" type="password" placeholder="Enter API Secret" onChange={hedgeSecretChanged} data-lpignore="true" autocomplete="off" />
                                            <Form.Text id="apiSecretHelpBlock" muted>
                                                <FontAwesomeIcon icon={faInfoCircle} />&nbsp;
                                                We will never display the secret. Leave this input blank to avoid overwriting the existing secret.
                                            </Form.Text>
                                        </Form.Group>
                                        <Form.Group className="mt-3" controlId="hedgeProviderIPRestriction">
                                            <Form.Label>Whitelisting IP</Form.Label>
                                            <InputGroup>
                                                <Form.Control name="restrictIP" type="text" pattern="^[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}$" disabled value={provider?.restrictIP || ''} />
                                                <ClipboardButton variant="outline-light" text={provider?.restrictIP} disabled={!provider?.restrictIP} />
                                            </InputGroup>
                                            <Form.Text id="restrictIPHelpBlock" muted>
                                                <FontAwesomeIcon icon={faInfoCircle} />&nbsp;
                                                This is the IP address from which we will do API requests - it is strongly advised to restrict
                                                the key to this IP.
                                            </Form.Text>
                                        </Form.Group>
                                        {hedgingEnabled &&
                                            <Alert variant="info">
                                                <FontAwesomeIcon icon={faInfoCircle} />&nbsp;
                                                Disable automated hedging before changing the hedge provider.
                                            </Alert>
                                        }
                                        {(provider.apiKey && !provider.enabled) &&
                                            <Alert variant="warning">
                                                <FontAwesomeIcon icon={faTriangleExclamation} />&nbsp;
                                                The hedging provider is not enabled. The latest status is: &apos;{provider.status}&apos;.
                                            </Alert>
                                        }
                                    </>
                                }
                            </Fragment>,
                        )}
                    </AjaxForm>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={hideConfig} variant="secondary">Close</Button>
                </Modal.Footer>
            </Modal>

            <Accordion.Item eventKey={eventKey}>
                <Accordion.Header as="h4">
                    <BalanceAccordionTitle status={hedgeStatus} okIcon={hedgeProviderIcons[hedgeProvider?.niceName]} tooltip={hedgeProvider?.niceName}>
                        Hedge Account
                        <NumericFormat displayType="text" thousandSeparator={','} decimalScale={0} prefix={'$'} value={hedgeCollateral.total} />
                    </BalanceAccordionTitle>
                </Accordion.Header>
                <Accordion.Body>
                    {(hedgeCollateral.total > 0) &&
                        <>
                            <h6 className="text-center">Collateral Balance</h6>
                            <CollateralChart collateral={hedgeCollateral} />
                        </>
                    }
                    {(hedgeCollateral.total > 0) && !!hedgePositions.length &&
                        <>
                            <h6 className="text-center">Futures Positions</h6>
                            <HedgePositionsChart positions={hedgePositions} />
                        </>
                    }
                    <CloseHedgesButton className="ms-auto" />
                    <Form.Group controlId="hedgeProviderConnection" className="mt-4">
                        <InputGroup>
                            <>
                                {(!hedgeProvider || hedgeProvider.enabled) &&
                                    <HoverTip tooltip="Configure API key">
                                        <Button variant="outline-secondary" onClick={() => showConfig()} disabled={!hedgeProviders.length || ['loading', 'unknown'].includes(hedgeStatus)}>
                                            <FontAwesomeIcon icon={faCog} />
                                        </Button>
                                    </HoverTip>
                                }
                                {(hedgeProvider && !hedgeProvider.enabled) &&
                                    <HoverTip tooltip="This provider is not enabled.">
                                        <Button variant="warning" onClick={() => showConfig()} disabled={!hedgeProviders.length || ['loading', 'unknown'].includes(hedgeStatus)}>
                                            <FontAwesomeIcon icon={faCog} />
                                        </Button>
                                    </HoverTip>
                                }
                            </>
                            <FloatingLabel label={`${hedgeProvider?.niceName || 'Provider'} API Key`}>
                                <Form.Control type="text" readOnly value={hedgeProvider?.abbrKey || ''} data-lpignore="true" />
                            </FloatingLabel>
                        </InputGroup>
                    </Form.Group>
                </Accordion.Body>
            </Accordion.Item>
        </>
    );
}

export default HedgeAccordionItem;
