import {useAuth0} from '@auth0/auth0-react';

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

import {LinkContainer} from 'react-router-bootstrap';

import LoginButton from './LoginButton';

import SignupButton from '../../../components/SignupButton';

const Header = () => {
    const {isAuthenticated} = useAuth0();

    return (
        <Navbar bg="transparent" variant="dark" fixed="top">
            <Container></Container>
            <Nav className="me-3 justify-content-end">
                {!isAuthenticated && (
                    <>
                        <Nav.Link><SignupButton variant="outline-light">Sign&nbsp;up</SignupButton></Nav.Link>
                        <Nav.Link><LoginButton variant="outline-light">Log&nbsp;in</LoginButton></Nav.Link>
                    </>
                )}
                {isAuthenticated && (
                    <>
                        {(window.location.pathname !== '/dashboard') && (
                            <Nav.Link><LinkContainer to="/dashboard"><Button variant="outline-light">Dashboard</Button></LinkContainer></Nav.Link>
                        )}
                    </>
                )}
            </Nav>
        </Navbar>
    );
};

export default Header;
