import {useEffect} from 'react';
import {useAuth0} from '@auth0/auth0-react';
import {useLocation} from 'react-router-dom';

const Referral = () => {
    const {isAuthenticated, loginWithRedirect} = useAuth0();
    const loc = useLocation();

    useEffect(() => {
        const searchParams = new URLSearchParams(loc.search);
        if (isAuthenticated) {
            window.location.replace('/dashboard');
        } else {
            loginWithRedirect({
                appState: {
                    returnTo: `/signup?referralCode=${searchParams.get('code')}`,
                },
                authorizationParams: {
                    // eslint-disable-next-line camelcase
                    screen_hint: 'signup',
                },
            }).then();
        }
    }, [loc.search, isAuthenticated, loginWithRedirect]);

    return (<></>);
};

export default Referral;
