import {useEffect, useState} from 'react';

import Accordion from 'react-bootstrap/Accordion';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Modal from 'react-bootstrap/Modal';
import {ToggleButtonGroup, ToggleButton} from 'react-bootstrap';

import {NumericFormat} from 'react-number-format';

import {faTriangleExclamation, faInfoCircle, faCog, faQrcode, faGasPump, faGift} from '@fortawesome/free-solid-svg-icons';
import {faEthereum} from '@fortawesome/free-brands-svg-icons';
import {faGLP} from '../../../../src/fontawesome/custom-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import GLPCompositionChart from './chart';
import BalanceAccordionTitle from '../BalanceAccordionTitle';
import {useDashboardContext} from '../../Context';
import {useAPI} from '../../../../components/APIProvider';
import AjaxForm from '../../../../components/AjaxForm';
import HoverTip from '../../../../components/HoverTip';
import ClipboardButton from '../../../../components/ClipboardButton';
import QRCodeModal from '../../../../components/QRCodeModal';
import CurrencyConversion from '../../../../components/CurrencyConversion';

import {chainIcons, currencyIcons} from '../../../../src/fontawesome/icon-groups';

const POLLING_INTERVAL_MS = 30000;

function GLPAccordionItem ({eventKey}) {
    const {api} = useAPI();
    const {glpWallet, setGlpWallet, glpBalance, setGLPBalance, hedgingEnabled} = useDashboardContext();
    const [glpStatus, setGLPStatus] = useState('loading');
    const [ethBalance, setETHBalance] = useState({
        ETH: '-',
        USD: '-',
    });
    const [rewards, setRewards] = useState({
        currency: null,
        total: '-',
        USD: '-',
    });
    const [showConfig, setShowConfig] = useState(false);
    const [showQR, setShowQR] = useState(false);
    const [glpComposition, setGlpComposition] = useState(null);

    const [glpChartSrc, setGlpChartSrc] = useState('composition');
    const glpChartOptions = {
        composition: {
            title: 'AUM Composition',
            tooltip: 'The composition of the pool is made up by deposits of different tokens.  Your contribution for '
                + 'each token is determined by scaling the composition of the pool as a whole down to the size of your GLP balance.',
            path: 'glp/composition',
        },
        exposure: {
            title: 'Pool Exposure',
            tooltip: 'As traders open long or short positions via GMX, your exposure to those assets decreases or '
                + 'increases accordingly, resulting in different levels as compared with the AUM contributions.  It is this exposure which we hedge to maintain a neutral position.',
            path: 'glp/exposure',
        },
    };

    function hideConfig () {
        setShowConfig(false);
    }

    function hideQR () {
        setShowQR(false);
    }

    function getBalance (signal) {
        api.get('glp/balances', {signal}).then(response => {
            const {balances, rewards: glpRewards, conversionsUSD} = response?.data ?? {};
            setGLPBalance({
                GLP: +balances?.GLP?.total,
                USD: balances?.GLP?.total * conversionsUSD?.GLP?.price,
            });
            setETHBalance({
                ETH: +balances?.ETH?.total,
                USD: balances?.ETH?.total * conversionsUSD?.ETH?.price,
            });
            setRewards({
                currency: glpRewards?.currency,
                total: +glpRewards?.total,
                USD: +glpRewards?.total * conversionsUSD?.[glpRewards?.currency]?.price,
            });
        }).catch(error => {
            if (error.code === 'ERR_CANCELED') {
                return;
            }
            console.error(error);
        });
    }

    function getWallet (signal) {
        setGLPStatus('loading');
        api.get('glp/wallet', {signal}).then(response => {
            setGlpWallet(response.data);
            if (!response.data.enabled) {
                setGLPStatus('disabled');
            } else {
                setGLPStatus('OK');
            }
        }).catch(error => {
            if (error.code === 'ERR_CANCELED') {
                return;
            }
            console.error(error);
            if (error.response?.status === 404) {
                setGlpWallet(null);
                setGLPStatus('missing');
            } else {
                setGLPStatus('unknown');
            }
        });
    }

    function getComposition (signal) {
        api.get(glpChartOptions[glpChartSrc].path, {signal}).then(response => {
            setGlpComposition(response.data);
        }).catch(error => {
            if (error.code === 'ERR_CANCELED') {
                return;
            }
            console.error(error);
        });
    }

    function onSubmitted (err, data) {
        if (!err) {
            reloadGLPData();
        }
    }

    function reloadGLPData (signal) {
        getBalance(signal);
        getComposition(signal);
        getWallet(signal);
    }

    useEffect(() => {
        const controller = new AbortController();

        reloadGLPData(controller.signal);
        const pollInterval = setInterval(() => reloadGLPData(controller.signal), POLLING_INTERVAL_MS);

        return () => {
            controller.abort();
            clearInterval(pollInterval);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [glpChartSrc]);

    return (
        <>
            <Modal
                show={showConfig}
                onHide={hideConfig}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        GLP Wallet
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="d-flex justify-content-center">
                    <AjaxForm formId="glpWalletForm" method="POST" path="glp/wallet" submitText="Save & Enable" onSubmitted={onSubmitted}>
                        <Form.Group className="mb-3" controlId="glpChain">
                            <Form.Label>EVM Chain</Form.Label>
                            <Form.Select name="chainId" aria-label="EVM Chain" defaultValue="42161" disabled>
                                <option value="42161">Arbitrum One</option>
                            </Form.Select>
                            <Form.Text id="chainHelpBlock" muted>
                                <FontAwesomeIcon icon={faInfoCircle} />&nbsp;
                                GLP on Avalanche is not currently supported.
                            </Form.Text>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="glpWalletAddress">
                            <Form.Label>Wallet Address</Form.Label>
                            <Form.Control name="address" type="text" required pattern="^(0x)[0-9A-Fa-f]{40}$" className="font-monospace" data-lpignore="true"
                                          placeholder="Enter address" defaultValue={glpWallet?.address} readOnly={hedgingEnabled}
                            />
                            <Form.Text id="addressHelpBlock" muted>
                                <FontAwesomeIcon icon={faInfoCircle} />&nbsp;
                                Address must start with <span className="font-monospace">0x</span>, followed by exactly 40 hexadecimal characters.
                                EIP-55 checksum will be applied automatically.
                            </Form.Text>
                        </Form.Group>
                        {hedgingEnabled &&
                            <Alert variant="info">
                                <FontAwesomeIcon icon={faInfoCircle} />&nbsp;
                                Disable automated hedging before changing the GLP wallet address.
                            </Alert>
                        }
                        {(glpWallet && !glpWallet.enabled) &&
                            <Alert variant="warning">
                                <FontAwesomeIcon icon={faTriangleExclamation} />&nbsp;
                                This wallet is not enabled.
                            </Alert>
                        }
                    </AjaxForm>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={hideConfig} variant="secondary">Close</Button>
                </Modal.Footer>
            </Modal>

            <QRCodeModal show={showQR} onHide={hideQR} title={`${glpWallet?.friendlyName || 'Arcus GLP'} Wallet Address`} value={glpWallet?.address} />

            <Accordion.Item eventKey={eventKey}>
                <Accordion.Header as="h4">
                    <BalanceAccordionTitle status={glpStatus} okIcon={chainIcons[glpWallet?.chainName]} tooltip={glpWallet?.chainName}>
                        GLP Wallet
                        <NumericFormat displayType="text" thousandSeparator={','} decimalScale={0} prefix={'$'} value={glpBalance.USD} />
                    </BalanceAccordionTitle>
                </Accordion.Header>
                <Accordion.Body>
                    <CurrencyConversion className="mb-2"
                                        leftTooltip="GLP" leftValue={glpBalance.GLP} rightValue={glpBalance.USD}
                                        leftSymbol={<FontAwesomeIcon icon={faGLP} className="fa-2fw" />}
                    />
                    <HoverTip className="tooltip-wide" expireAfter={2000}
                              tooltip="ETH balance shown for reference only as source of gas fees on this wallet.  These funds are not considered Assets Under Management for Arcus yield.">
                        <CurrencyConversion className="mb-2"
                                            leftTooltip="ETH" leftValue={ethBalance.ETH} rightValue={ethBalance.USD}
                                            leftSymbol={<><FontAwesomeIcon icon={faEthereum} fixedWidth /><FontAwesomeIcon icon={faGasPump} fixedWidth /></>}
                        />
                    </HoverTip>
                    {rewards?.currency &&
                        <CurrencyConversion className="mb-2"
                                            leftTooltip={`Unclaimed Rewards (${rewards?.currency})`} leftValue={rewards?.total} rightValue={rewards?.USD}
                                            leftSymbol={<><FontAwesomeIcon icon={currencyIcons[rewards?.currency]} fixedWidth /><FontAwesomeIcon icon={faGift} fixedWidth /></>}
                        />
                    }

                    {glpComposition &&
                        <>
                            <div className='d-flex justify-content-center pt-2'>
                                <ToggleButtonGroup
                                    type="radio"
                                    name="glpChartToggle"
                                    defaultValue={glpChartSrc}
                                    onChange={(val) => setGlpChartSrc(val)}
                                >
                                    {Object.keys(glpChartOptions).map((key) => (
                                        <ToggleButton
                                            id={`radio-${key}`}
                                            key={key}
                                            type="radio"
                                            value={key}
                                            variant="outline-secondary"
                                        >
                                            <HoverTip tooltip={glpChartOptions[key].tooltip} delay={500}>
                                                <h6>{glpChartOptions[key].title}</h6>
                                            </HoverTip>
                                        </ToggleButton>
                                    ))}
                                </ToggleButtonGroup>
                            </div>

                            <GLPCompositionChart composition={glpComposition} />
                        </>
                    }

                    <Form.Group controlId="glpWalletConnection">
                        <InputGroup>
                            {(!glpWallet || glpWallet.enabled) &&
                                <HoverTip tooltip="Configure GLP wallet">
                                    <Button variant="outline-secondary" onClick={() => setShowConfig(true)} disabled={['loading', 'unknown'].includes(glpStatus)}>
                                        <FontAwesomeIcon icon={faCog} />
                                    </Button>
                                </HoverTip>
                            }
                            {(glpWallet && !glpWallet.enabled) &&
                                <HoverTip tooltip="This wallet is not enabled.">
                                    <Button variant="warning" onClick={() => setShowConfig(true)} disabled={['loading', 'unknown'].includes(glpStatus)}>
                                        <FontAwesomeIcon icon={faCog} />
                                    </Button>
                                </HoverTip>
                            }
                            <FloatingLabel label={`${glpWallet?.friendlyName || 'Arcus GLP'} Wallet Address`}>
                                <Form.Control type="text" readOnly value={glpWallet?.address || ''} data-lpignore="true" />
                            </FloatingLabel>
                            <HoverTip tooltip="View as QR code">
                                <Button variant="outline-secondary" onClick={() => setShowQR(true)} disabled={!glpWallet?.address}><FontAwesomeIcon icon={faQrcode} /></Button>
                            </HoverTip>
                            <ClipboardButton variant="outline-secondary" text={glpWallet?.address} disabled={!glpWallet?.address} />
                        </InputGroup>
                    </Form.Group>
                </Accordion.Body>
            </Accordion.Item>
        </>
    );
}

export default GLPAccordionItem;
