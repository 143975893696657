import {useState, useRef, createContext, useContext} from 'react';
// TODO: Perhaps https://react-pdf-viewer.dev/ would be better
// eslint-disable-next-line no-shadow
import {Document, Page, pdfjs} from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

import './index.css';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faMagnifyingGlass} from '@fortawesome/free-solid-svg-icons';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const SCROLL_THRESHOLD_FACTOR = 0.99;

function useTermsAndConditions () {
    const [termsShown, setTermsShown] = useState(false);
    const [termsScrolled, setTermsScrolled] = useState(false);
    const [termsAccepted, setTermsAccepted] = useState(false);

    const context = createContext({
        termsShown,
        setTermsShown,
        termsScrolled,
        setTermsScrolled,
        termsAccepted,
        setTermsAccepted,
    });

    return useContext(context);
}

function TermsPDF ({onScrolled}) {
    const [numPages, setNumPages] = useState(null);
    const listInnerRef = useRef();

    function onDocumentLoadSuccess ({numPages: nextNumPages}) {
        setNumPages(nextNumPages);
    }

    const onScroll = () => {
        if (listInnerRef.current) {
            const {scrollTop, scrollHeight, clientHeight} = listInnerRef.current;
            if (scrollTop + clientHeight >= scrollHeight * SCROLL_THRESHOLD_FACTOR) {
                onScrolled();
            }
        }
    };

    return (
        <div className="pdf-container" ref={listInnerRef} onScroll={onScroll}>
            <Document file={`${process.env.REACT_APP_ASSET_BUCKET}/terms.pdf`} onLoadSuccess={onDocumentLoadSuccess}>
                {Array.from(new Array(numPages), (el, index) => (
                    <Page key={`page_${index + 1}`} pageNumber={index + 1} scale={1} />
                ))}
            </Document>
        </div>
    );
}

function TermsModal ({context}) {
    const hideTerms = () => context.setTermsShown(false);

    function acceptTerms () {
        context.setTermsAccepted(true);
        hideTerms();
    }

    function declineTerms () {
        context.setTermsAccepted(false);
        hideTerms();
    }

    return (
        <Modal
            show={context.termsShown}
            onHide={hideTerms}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Arcus Terms & Conditions
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="bg-light text-dark d-flex justify-content-center">
                <TermsPDF onScrolled={() => context.setTermsScrolled(true)} />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={acceptTerms} disabled={!context.termsScrolled}>Read, understood, and accepted</Button>
                <Button variant="secondary" onClick={declineTerms}>Nope</Button>
            </Modal.Footer>
        </Modal>
    );
}

function TermsCheckbox ({context}) {
    function showTerms (e) {
        e.preventDefault();
        context.setTermsShown(true);
    }

    return (
        <>
            <Button variant="info" className="mb-3" onClick={showTerms}>
                <FontAwesomeIcon icon={faMagnifyingGlass} />
                Terms & Conditions</Button>
            <Form.Check
                type="switch"
                name="acceptedTerms"
                label={<>I have read, understood, and accepted the Terms & Conditions</>}
                value="true"
                required
                checked={context.termsAccepted}
                onChange={() => context.setTermsAccepted(!context.termsAccepted)}
                disabled={!context.termsScrolled}
            />
        </>
    );
}

const Terms = {
    useTermsAndConditions,
    PDF: TermsPDF,
    Modal: TermsModal,
    Checkbox: TermsCheckbox,
};

export default Terms;
